/**
 * Notification React Component
 * @copyright 2021-2023 Soter Technologies, LLC. All rights reserved.
 * @file Notification.js
 * @author Matt Schreider, Paul Scala, Kyle Watkins
 */

import React from 'react'

/**
 * Notification Component
 * @param {object} props Component props
 * @param {object} props.children Component children
 * @returns Circle Component
 */
function Notification(props) {
    const {className, count, showCount = true} = props

    return (
        <div className={"webSocketNotification" + ` ${className}`}>
            {
                showCount && 
                    <span>
                        {count}
                    </span>
            }
        </div>
    )
}

export default Notification
