/**
 * No Auth Alerts Page Component
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file noAuthError.js
 * @author Kyle Watkins, Matt Schreider, Paul Scala
 */

import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import ApiManager from '../api/ApiManager'

/**
 * @description Loading page where aquiring user login info is handled
 * @param {object} props Compononent props
 * @param {string} props.name Component name
 * @returns Site not found page
 */
function NoAuthError(props) {
    /**
     * Pseudo Code
     *  Get nam from props
     *  Init message and actions
     *  If name is timeout
     *      Populate message in action
     *  If name is pagenotfound
     *      Populate message and actions
     */
    const { name } = props
    let message
    let actions
    if (name === 'timeout') {
        message = 'Server Time Out'
        actions = (
            <Button onClick={() => {
                ApiManager.session.initializeSession()
                props.history.go(0)
            }}
            >
                Try Again
            </Button>
        )
    }
    else if (name === 'pagenotfound') {
        message = 'Page Not Found'
        actions = (
            <Button onClick={() => {
                props.history.push('/login')
            }}
            >
                Go to login
            </Button>
        )
    }

    return (
        <div className="full glass">
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%", flexDirection: "column"}}>
                <h1>
                    {message}
                </h1>
                <br></br>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    {actions}
                </div>
            </div>
        </div>
    )
}

export default withRouter(NoAuthError)
