/**
 * REST request router
 * @copyright 2021-2023 Soter Technologies, LLC. All rights reserved.
 * @file ratingsLiveAnalytics.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/

class RatingsLiveAnalytics {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * Get ratings live analytics by site
     * @param {string} siteId Site id to get by
     * @param {object} query
     * @param {string[]} query.type Ratings types to get analytics for. Defaults to empty array which means all ratings types will be returned
     * @param {string} query.interval Interval for ratings live data
     * @param {number|Date} query.startDate Start time to get ratings live data for.
     * @param {number|Date} query.enddate End time to get ratings live data for.
     * @param {string} query.timezone Timezone for appropriate time conversion
     * @param {number} query.priorityLimit priority limit value
     * @returns {ResponseObject} Api response object
     */
    async getRatingsLiveAnalytics(siteId, {type = [], interval = 'Day', startDate = null, endDate = null, timeZone = '', priorityLimit = null} = {}) {
        let searchFilters = {
            ...(startDate === "" || startDate === undefined) ? {} : {startDate},
            ...(endDate === "" || endDate === undefined) ? {} : {endDate},
            ...(type === [] || type === undefined) ? {} : {type},
            ...(interval === "" || interval === undefined) ? {} : {interval},
            ...(timeZone === "" || timeZone === undefined) ? {} : {timeZone},
        }

        let query = this.session.getQuery({page: null, siteId, searchFilters})
        let config = {
            method: 'get',
            url: `/api/analyticsdata/ratingslive?${(priorityLimit !== null ? `priorityLimit=${priorityLimit}` : '') + query}`
        }

        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
}

export default RatingsLiveAnalytics