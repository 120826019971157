/**
 * Class for handling webhook requests REST request
 * @copyright 2021-2023 Soter Technologies, LLC. All rights reserved.
 * @file webhookConfigs.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/

class WebhookRequests {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * @description Gets webhook requests
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @returns {ResponseObject} Api response object
     */
    async getWebhookRequests(siteId, {page = 0, order = "ASC", search = "", sortBy = ""} = {}) {
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search: encodeURIComponent(search)},
            ...(sortBy === "") ? {} : {sortBy},
        }

        let query = this.session.getQuery({page, siteId, searchFilters})
        
        let config = {
            method: 'get',
            url: `/api/webhookrequests${"?" + query}`,
        }
          
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Get webhook request count
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @returns {ResponseObject} Api response object
     */
    async getCount(siteId, {page = 0, order = "ASC", search = "", sortBy = ""} = {}) {
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search: encodeURIComponent(search)},
            ...(sortBy === "") ? {} : {sortBy},
            countOnly: true
        }

        let query = this.session.getQuery({page, siteId, searchFilters})
        
        let config = {
            method: 'get',
            url: `/api/webhookrequests${"?" + query}`,
        }
          
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get Webhook Request
     * @param {string} id Id of webhook request to get 
     * @param {object} options Options when getting webhook request
     * @param {('none'|'all'|'params')[]} options.details Whether to populate return object with additional details. Defaults to none, which means no details will be returned
     * @param {boolean} options.populate If true populate ids with names and ids
     * @returns {ResponseObject} Api response object
     */
    async getWebhookRequest(id, {details = [], populate = true} = {}) {
        let params = []
        
        if (populate === true) {
            params.push('populate=simple')
        }
        
        if (details.length > 0) {
            details = 'details=' + details.join('&details=')
            params.push(details)
        }

        let config = {
            method: 'get',
            url: `/api/webhookrequests/${id}${(params.length !== 0 ? '?' + params.join('&') : '')}`,
        }
          
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get Webhook Request Params
     * @param {string} id Id of webhook request to get 
     * @param {object} options Options when getting webhook request
     * @param {boolean} options.populate If true populate ids with names and ids
     * @returns {ResponseObject} Api response object
     */
    async getWebhookRequestParams(id, {populate = true} = {}) {
        let params = []
        
        if (populate === true) {
            params.push('populate=simple')
        }
        
        let config = {
            method: 'get',
            url: `/api/webhookrequests/${id}/params${(params.length !== 0 ? '?' + params.join('&') : '')}`,
        }
          
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Creates a specified webhook request
     * @param {Object} params Function parameters
     * @param {string} params.name Webhook Request name
     * @param {string} params.contentType Webhook content type
     * @param {'off'|'on'|'config'} params.trustSelfSignedCert Webhook trust self signed cert
     * @param {string} params.url Webhook url
     * @param {string} params.auth Webhook request authorization
     * @param {string} params.method Webhook request method
     * @param {object[]} params.headers Webhook request headers
     * @param {object[]} params.body Webhook request body
     * @param {object[]} params.urlVariables Webhook request url variables
     * @param {object[]} params.cookies Webhook request cookies
     * @param {string} params.siteId Site id of webhook request
     * @returns {ResponseObject} Api response object
     */
    async createWebhookRequest({name = '', contentType = '', trustSelfSignedCert = '', url = '', method = '', auth = [], headers = [], body = [], urlVariables = [], cookies = [], siteId = null} = {}) {
        if (siteId === null) {
            siteId = this.session.getSiteId()
        }

        let data = {
            Name: name,
            ContentType: contentType,
            Url: url,
            Method: method,
            Auth: auth,
            Headers: headers,
            Body: body,
            UrlVariables: urlVariables,
            Cookies: cookies,
            Site: siteId,
            TrustSelfSignedCert: trustSelfSignedCert
        }

        let config = {
            method: 'post',
            url: '/api/webhookrequests',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }
          
        let res = await this.axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText, 
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })    
    } 

    /**
     * @description Edit a specified webhook request
     * @param {Object} params Function parameters
     * @param {string} params.name Webhook Request name
     * @param {string} params.contentType Webhook content type
     * @param {'off'|'on'|'config'} params.trustSelfSignedCert Webhook trust self signed cert
     * @param {string} params.url Webhook url
     * @param {string} params.auth Webhook request authorization
     * @param {string} params.method Webhook request method
     * @param {object[]} params.headers Webhook request headers
     * @param {object[]} params.body Webhook request body
     * @param {object[]} params.urlVariables Webhook request url variables
     * @param {object[]} params.cookies Webhook request cookies
     * @returns {ResponseObject} Api response object
     */
    async editWebhookRequest(id, {name = '', contentType = '', trustSelfSignedCert = '', url = '', method = '', auth = [], headers = [], body = [], urlVariables = [], cookies = []} = {}) {
        let data = {
            Name: name,
            ContentType: contentType,
            TrustSelfSignedCert: trustSelfSignedCert,
            Url: url,
            Auth: auth,
            Method: method,
            Headers: headers,
            Body: body,
            UrlVariables: urlVariables,
            Cookies: cookies,
        }

        let config = {
            method: 'put',
            url: `/api/webhookrequests/${id}`,
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }
          
        let res = await this.axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText, 
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })    
    } 

    /**
     * @description Delete webhookrequest by id
     * @param {string} id WebhookRequest uuid     
     * @returns {ResponseObject} Api response object
     */
    async deleteWebhookRequest({id}) {
        let config = {
            method: 'delete',
            url: `/api/webhookrequests/${id}`
        }

        let res = await this.axios(config)

        return({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        }) 
    }

    /**
     * Get filtered webhookRequest names
     * @param {string} siteId Site id to get webhookConfigs for 
     * @param {object} query Query to filter
     * @param {number} query.page Page number of data
     * @param {string} query.order Order of data
     * @param {string} query.search String to search 
     * @param {string} query.sortBy Field to sort by
     * @param {boolean} query.countOnly Only get the count of webhookConfigs back
     * @returns {ResponseObject} Api response object
     */
    async lookupWebhookRequest(siteId, {page = 0, order = 'ASC', search = "", sortBy = "", countOnly = false} = {}) {
        /**
         * Pseudo Code
         *  Initialize search filters
         *  Get query to filter by
         *  Set request config
         *  Get webhookConfigs
         */

        // Initialize search filters
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
        }

        // Get query to filter by
        let query = this.session.getQuery({page, siteId, searchFilters, countOnly})

        // Set request config
        let config = {
            method: 'get',
            url: `/api/webhookrequests/lookup${"?" + query}`,
        }

        // Get webhookConfigs
        let res = await this.axios(config)
    
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get a webhookRequest's name by id
     * @param {string} id Id of webhookRequest to get name for
     * @returns {ResponseObject} Api response object
     */
    async getWebhookRequestName(id) {
        // Set request config
        let config = {
            method: 'get',
            url: `/api/webhookrequests/${id}/name`,
        }
        
        // Get webhookRequest
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    } 
}

export default WebhookRequests