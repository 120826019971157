import React from "react"
import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"
import './resources/less/icomoon.less'
import "./resources/js/TypeDefinitions"
import RouteManager from "./stateless/RouteManager"

const history = createBrowserHistory()

function App() {
    return ( 
        <Router history={history}>
            <RouteManager />
        </Router>
    )
}

export default App