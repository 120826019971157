/**
 * Class for handling blacklists REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file blacklists.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/

class Blacklists {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * Get blacklists
     * @param {object} query Query for filtering
     * @param {number} query.page Page number to get
     * @param {number} query.offset Time offset of page
     * @param {string} query.receiver Email or phone number of user to get blacklists for
     * @param {string} query.startDate Start date of blacklist to get
     * @param {string} query.endDate End date of blacklist to get
     * @param {string} query.deviceId Device id to get blacklists for
     * @param {string} query.siteId Site id to get blacklists for
     * @param {string} query.userId User id to get blacklists for
     * @param {string} query.status Status of blacklist
     * @param {string} query.order Order of data
     * @param {string} query.sortBy Field to sort by
     * @param {boolean} query.countOnly Only get the count of blacklists back
     * @returns {ResponseObject} API response object
     */
    async getBlacklists({offset = null, page = 0, receiver = null, startDate = null, endDate = null, userId = null, type = null, order = null, isBlocked = null, countOnly = false} = {}) {
        let searchFilters = {
            ...(receiver !== null && {receiver: encodeURIComponent(receiver)}),
            ...(userId !== null && {userId: userId}),
            ...(type !== null && {type: type}),
            ...(offset !== null && {offset: offset}),
            ...(order !== null && {order: order}),
            ...(isBlocked !== null && {isBlocked: isBlocked}),
            ...(startDate !== null && {startDate: startDate}),
            ...(endDate !== null && {endDate: endDate})
        }
        
        let query = this.session.getQuery({ page, countOnly, searchFilters })

        let config = {
            method: 'get',
            url: `/api/blacklists?${query}`,
        }

        let res = await this.axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get a single blacklist
     * @param {string} id Id of blacklist to get 
     * @returns {ResponseObject} API response object
     */
    async getBlacklist(id) {
        let config = {
            method: 'get',
            url: `/api/blacklists/${id}`,
        }

        let res = await this.axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Edit blacklist
     * @param {object} params Params to edit blacklist
     * @param {string} params.id Id of blacklist to edit
     * @param {string} params.isBlocked Set to false to unblock blacklisting 
     * @returns {ResponseObject} API response object
     */
    async saveBlacklist({id, isBlocked = null}) {
        let config = {
            method: 'put',
            url: `/api/blacklists/${id}`,
            headers: { 
                'Content-Type': 'application/json'
            },
            data: {
                ...(isBlocked !== null && {IsBlocked: isBlocked})
            }
        }

        let res = await this.axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })

    }
}

export default Blacklists