/**
 * Class for handling user roles REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file userroles.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/

class UserRoles {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * @description List user roles
     * @returns {ResponseObject} Api response object
     */
    async listUserRoles() {
        let config = {
            method: 'get',
            url: `/api/userroles/list`,
        }

        let res = await this.axios(config)

        return({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description All user roles
     * @returns {ResponseObject} Api response object
     */
    async allUserRoles() {
        let config = {
            method: 'get',
            url: `/api/userroles/all`,
        }

        let res = await this.axios(config)

        return({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
}

export default UserRoles