/**
 * Class for handling webhook log REST request
 * @copyright 2021-2023 Soter Technologies, LLC. All rights reserved.
 * @file webhookLog.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/

class WebhookLog {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * Get webhook log
     * @param {object} query Query for filtering
     * @param {number} query.page Page number to get
     * @param {number} query.offset Time offset of page
     * @param {string} query.webhookConfigId Webhook request id to get logs for
     * @param {string} query.webhookEventId Webhook event id to get logs for
     * @param {string} query.startDate Start date of webhook log to get
     * @param {string} query.endDate End date of webhook log to get
     * @param {string} query.deviceId Device id to get webhook logs for
     * @param {string} query.siteId Site id to get webhook logs for
     * @param {string} query.alertId Alert id to get webhook logs for
     * @param {string} query.statusCode Status code of webhook log
     * @param {string} query.order Order of data
     * @param {string} query.sortBy Field to sort by
     * @param {string[]} query.alertType Alert types to filter by
     * @param {boolean} query.countOnly Only get the count of webhook logs back
     * @param {'none'|'simple'} query.populate  If set to simple replace all ids to show name and id, default to none which means no ids will be populated in the return
     * @returns {ResponseObject} API response object
     */
    async getWebhookLog(siteId = null, {page = 0, offset = null, statusCode = null, webhookConfigId = null, webhookEventId = null, startDate = null, endDate = null, deviceId = null, alertId = null, alertType = [], order = "ASC", countOnly = false, populate = 'none'} = {}) {
        let searchFilters = {
            ...(webhookConfigId !== null && {webhookConfigId: webhookConfigId}),
            ...(webhookEventId !== null && {webhookEventId: webhookEventId}),
            ...(offset !== null && {offset: offset}),
            ...(deviceId !== null && {deviceId: deviceId}),
            ...(alertId !== null && {alertId: alertId}),
            order: order,
            ...(statusCode !== null && {statusCode: statusCode}),
            ...(startDate !== null && {startDate: startDate}),
            ...(endDate !== null && {endDate: endDate}),
            alertType
        }

        let query = this.session.getQuery({ page, siteId, searchFilters, countOnly })
    
        let config = {
            method: 'get',
            url: `/api/webhooklog?populate=${populate}${query ? `&${query}` : ''}`,
        }

        let res = await this.axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get admin webhook log
     * @param {object} query Query for filtering
     * @param {number} query.page Page number to get
     * @param {number} query.offset Time offset of page
     * @param {string} query.webhookConfigId Webhook request id to get logs for
     * @param {string} query.webhookEventId Webhook event id to get logs for
     * @param {string} query.startDate Start date of webhook log to get
     * @param {string} query.endDate End date of webhook log to get
     * @param {string} query.deviceId Device id to get webhook logs for
     * @param {string} query.siteId Site id to get webhook logs for
     * @param {string} query.alertId Alert id to get webhook logs for
     * @param {string} query.statusCode Status code of webhook log
     * @param {string} query.order Order of data
     * @param {string} query.sortBy Field to sort by
     * @param {string[]} query.alertType Alert types to filter by
     * @param {'none'|'simple'} query.populate  If set to simple replace all ids to show name and id, default to none which means no ids will be populated in the return
     * @param {boolean} query.countOnly Only get the count of webhook logs back
     * @returns {ResponseObject} API response object
     */
    async getAdminWebhookLog({page = 0, offset = null, siteId = null, statusCode = null, webhookConfigId = null, webhookEventId = null, startDate = null, endDate = null, deviceId = null, alertId = null, alertType = [], order = "ASC", countOnly = false, populate = 'none'} = {}) {
        let searchFilters = {
            ...(webhookConfigId !== null && {webhookConfigId: webhookConfigId}),
            ...(webhookEventId !== null && {webhookEventId: webhookEventId}),
            ...(offset !== null && {offset: offset}),
            ...(siteId !== null && {siteId: siteId}),
            ...(deviceId !== null && {deviceId: deviceId}),
            ...(alertId !== null && {alertId: alertId}),
            order: order,
            ...(statusCode !== null && {statusCode: statusCode}),
            ...(startDate !== null && {startDate: startDate}),
            ...(endDate !== null && {endDate: endDate}),
            alertType
        }

        let query = this.session.getQuery({ page, searchFilters, countOnly })
    
        let config = {
            method: 'get',
            url: `/api/webhooklog/all?populate=${populate}${query ? `&${query}` : ''}`,
        }

        let res = await this.axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
}

export default WebhookLog