/**
 * Class for handling ratings REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file ratings.js
 * @author Kyle Watkins, Paul Scala, Matt Schreider
 */

class Ratings {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * @description Get System Messages
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchfilters.severity Fitler by severity 
     * @returns {ResponseObject} Api response object 
     */
    async getComfortRating(siteId) {
        // TODO Add Severity to search 
        let query = this.session.getQuery({siteId})

        let config = {
            method: 'get',
            url: `/api/ratings${"?" + query}&Type=Comfort`,
        }

        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get air quality rating for site
     * @param {string} siteId 
     * @returns {ResponseObject} Api response object 
     */
    async getAirQualityRating(siteId) {
        // TODO Add Severity to search 
        let query = this.session.getQuery({siteId})

        let config = {
            method: 'get',
            url: `/api/ratings${"?" + query}&Type=AirQuality`,
        }

        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
}

export default Ratings