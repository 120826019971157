/**
 * REST request router
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file sensorAnalytics.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/

class SensorAnalytics {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * Get sensor analytics
     * @param {string} deviceId Device id to get by
     * @param {object} query Query settings object
     * @param {string} query.timeZone Timezone for approriate time conversion
     * @param {string} query.interval Interval for sensor data, defaults to 1hr
     * @param {string[]} query.types Sensor types to get analytics for. Defaults to empty array which means all sensor types will be returned
     * @param {number|Date} query.startDate Start time to get sensor data from. Defaults to 0 which means all sensor analytics will be returned
     * @param {number|Date} query.endDate End time to set sensor data chart to. Defaults to 0 which means all sensor analytics will be returned
     * @returns {ResponseObject} Api response object
     */
    async getSensorAnalytics(deviceId, { timeZone = '', interval = 'OneHour', types = [], startDate = 0, endDate = 0} = {}) {
        let type = types

        if (typeof(startDate) === "number" ) {
            startDate = startDate.toString()
        }
        if (typeof(endDate) === "number" ) {
            endDate = endDate.toString()
        }

        let searchFilters = {
            ...(startDate === "" || startDate === undefined) ? {} : {startDate},
            ...(endDate === "" || endDate === undefined) ? {} : {endDate},
            ...(type === [] || type === undefined) ? {} : {type},
            ...(interval === "" || interval === undefined) ? {} : {interval},
            ...(deviceId === "" || deviceId === undefined) ? {} : {deviceId},
            ...(timeZone === "" || timeZone === undefined) ? {} : {timeZone},
        }

        let query = this.session.getQuery({page: null, siteId: null, searchFilters})
        let config = {
            method: 'get', 
            url: `/api/data/sensors${"?" + query}`
        }

        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get sitewide sensor analytics data
     * @param {string} siteId Site Id to get data for
     * @param {Object} query Query settings object
     * @param {string} query.timeZone Timezone for approriate time conversion
     * @param {string} query.interval Time interval of sensor data
     * @param {string[]} query.type Sensor types to get analytics for. Defaults to empty array which means all sensor types will be returned
     * @param {number|Date} query.startTime Start time to get sensor data from. Defaults to "" which means all sensor analytics will be returned
     * @param {number|Date} query.endTime End time to set sensor data chart to. Defaults to "" which means all sensor analytics will be returned
     * @returns {ResponseObject} Api response object
     */
    async getSiteSensorAnalytics(siteId, { timeZone = '', interval = 'OneDay', type = [], startDate = "", endDate = ""} = {}) {
        let searchFilters = {
            ...((timeZone === '') ? {} : {timeZone}),
            interval,
            type,
            ...((startDate === "" || startDate === undefined) ? {} : {startDate: new Date(startDate).getTime()}),
            ...((endDate === "" || endDate === undefined) ? {} : {endDate: new Date(endDate).getTime()})
        }

        let query = this.session.getQuery({page: null, siteId, searchFilters})
        
        let config = {
            method: 'get',
            url: `/api/data/sensors/site${"?Method=Range&populate=simple&" + query}`,
        }

        let res = await this.axios(config)
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get graphical live sensor data
     * @param {string} deviceId Device id to get graphical live sensor data for
     * @returns {ResponseObject} Api response object
     */
    async getGraphicalLiveSensorData(deviceId = null) {
        let query = this.session.getQuery({page: null, deviceId})
        let config = {
            method: 'get',
            url: `/api/data/sensors/graphical/all?${query}`,
        }

        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
}

export default SensorAnalytics