/**
 * Class for handling analytics REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file alertAnalytics.js
 * @author Kyle Watkins, Paul Scala, Matt Schreider
 */

class AlertAnalytics {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * Get alert analytics
     * @param {string} siteId Site id to get alert analytics from
     * @param {object} query Query settings object
     * @param {string} query.timeZone Time zone to group alerts by, defaults to UTC 
     * @param {string} query.interval Interval to group alerts by, defaults to year
     * @param {string[]} query.types Alert types to get analytics for. Defaults to empty array which means all alert types will be returned
     * @param {string[]} query.deviceIds Device Ids to get alerts analytics for. Defaults to empty array which means alerts for all devices in site will be returned
     * @param {int|Date} query.startDate Start time to get alerts from. Defaults to 0
     * @param {string[]} query.groupIds Group Ids to get alerts from
     * @param {int|Date} query.endDate End time to get alerts to. Defaults to 0
     * @param {boolean} searchFilters.confirmed If true filter by confirmed, if false filter by unconfirmed
     * @returns {ResponseObject} Api response object
     */
    async getAlertAnalytics(siteId = null, {timeZone = '', interval = 'Year', deviceIds = [], groupIds = [], types = [], startDate = null, endDate = null, confirmed = null} = {}) {
        let type = types

        let searchFilters = {
            ...(startDate !== null && {startDate: startDate}),
            ...(endDate !== null && {endDate: endDate}),
            ...(type.length !== 0 && {type: type}),
            ...(interval !== '' && {interval: interval}),
            ...(timeZone !== '' && {timeZone: timeZone}),
            ...(deviceIds.length !== 0 && {deviceId: deviceIds}),
            ...(groupIds.length !== 0 && {groupId: groupIds}),
            ...(confirmed !== null && {confirmed: confirmed})
        }

        let query = this.session.getQuery({page: null, siteId, searchFilters})
        let config = {
            method: 'get',
            url: `/api/analyticsdata/alerts${"?Method=Range&" + query}`,
        }
        
        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get devices with most alerts per alert type
     * @param {string} siteId Site id to get alert analytics from
     * @param {object} query Query settings object
     * @param {string} query.timeZone Time zone to group alerts by, defaults to UTC 
     * @param {string[]} query.types Alert types to get analytics for. Defaults to empty array which means all alert types will be returned
     * @param {int|Date} query.startDate Start time to get alerts from. Defaults to 0
     * @param {int|Date} query.endDate End time to get alerts to. Defaults to 0
     * @param {string} query.mode Mode for specifying min or max results
     * @returns {ResponseObject} Api response object
     */
    async getDevicesByAlertType(siteId = null, {timeZone = '', types = [], startTime = null, endTime = null, mode = 'Max'} = {}) {
        let type = types

        let searchFilters = {
            ...(startTime !== null && {startTime: startTime}),
            ...(endTime !== null && {endTime: endTime}),
            ...(type.length !== 0 && {type: type}),
            ...(timeZone !== '' && {timeZone: timeZone}),
            mode,
        }

        let query = this.session.getQuery({page: null, siteId, searchFilters})
        let config = {
            method: 'get',
            url: `/api/analyticsdata/alerts/bytype${"?Method=Range&" + query}`,
        }

        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get site heatmap
     * @param {string} siteId Site id to get alert heatmap for
     * @param {object} query Query settings object
     * @param {string} query.timeZone Time zone to group alerts by, defaults to UTC 
     * @param {string[]} query.types Alert types to get analytics for. Defaults to empty array which means all alert types will be returned
     * @param {string[]} query.deviceIds Device Ids to get alerts analytics for. Defaults to empty array which means alerts for all devices in site will be returned
     * @param {int|Date} query.startDate Start time to get alerts from. Defaults to 0
     * @param {string[]} query.groupIds Group Ids to get alerts from
     * @param {int|Date} query.endDate End time to get alerts to. Defaults to 0
     * @param {boolean} searchFilters.confirmed If true filter by confirmed, if false filter by unconfirmed
     * @returns {ResponseObject} Api response object
     */
    async getAlertHeatmapForSite(siteId = null, {timeZone = '', deviceIds = [], groupIds = [], types = [], startDate = null, endDate = null, confirmed = null} = {}) {
        let type = types

        let searchFilters = {
            ...(startDate !== null && {startDate: startDate}),
            ...(endDate !== null && {endDate: endDate}),
            ...(type.length !== 0 && {type: type}),
            ...(timeZone !== '' && {timeZone: timeZone}),
            ...(deviceIds.length !== 0 && {deviceId: deviceIds}),
            ...(groupIds.length !== 0 && {groupId: groupIds}),
            ...(confirmed !== null && {confirmed: confirmed})
        }

        let query = this.session.getQuery({page: null, siteId, searchFilters})
        let config = {
            method: 'get',
            url: `/api/analyticsdata/alerts/heatmap/by/site${"?Method=Range&" + query}`,
        }
        
        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get device heatmap
     * @param {string} deviceId Device Ids to get alerts heatmap for
     * @param {object} query Query settings object
     * @param {string} query.siteId Site id to get alert heatmap for
     * @param {string} query.timeZone Time zone to group alerts by, defaults to UTC 
     * @param {string[]} query.types Alert types to get analytics for. Defaults to empty array which means all alert types will be returned
     * @param {int|Date} query.startDate Start time to get alerts from. Defaults to 0
     * @param {int|Date} query.endDate End time to get alerts to. Defaults to 0
     * @param {boolean} searchFilters.confirmed If true filter by confirmed, if false filter by unconfirmed
     * @returns {ResponseObject} Api response object
     */
    async getAlertHeatmapForDevice(deviceId, {siteId = null, timeZone = '', types = [], startDate = null, endDate = null, confirmed = null} = {}) {
        let type = types

        let searchFilters = {
            ...(startDate !== null && {startDate: startDate}),
            ...(endDate !== null && {endDate: endDate}),
            ...(type.length !== 0 && {type: type}),
            ...(timeZone !== '' && {timeZone: timeZone}),
            ...(confirmed !== null && {confirmed: confirmed}),
            ...(siteId !== null && {siteId: siteId})
        }

        let query = this.session.getQuery({page: null, deviceId, searchFilters})
        let config = {
            method: 'get',
            url: `/api/analyticsdata/alerts/heatmap/by/device${"?Method=Range&" + query}`,
        }
        
        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

}

export default AlertAnalytics