/**
 * Site Controller React Component
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file SiteController.js
 * @author Kyle Watkins, Matt Schreider, Paul Scala
 */

import React, { useEffect, useRef, useState } from 'react'
import { Link, withRouter, useHistory } from 'react-router-dom'
import ApiManager from '../api/ApiManager'
import { transformPath } from '../utilities/transformUtility'
import { decodeSearchParams, encodeSearchParams } from '../utilities/utilityFunctions'
import { Icon, Loader, Menu, Ref } from 'semantic-ui-react'
import { WindowSize } from '../stateless/WindowSize'
import '../resources/less/topBar.less'
import EotLive from './EotLive'
import Notification from '../stateless/Notification'

/**
 * @description Controls NavBar and TopBar Site functions
 * @param {Object} props
 * @param {string} props.location Current page location
 * @param {boolean} props.loading Loading Screen Controller 
 * @param {boolean} props.started Application has opened
*/
function TopBar(props) {
    /**
     * Pseudo Code
     *  Init SubsiteMenu Refs
     *  Get current siteId in url
     *  If siteId is not available in url
     *      Get default siteId
     *  Init state
     *  Init get site function
     *  Init previous location ref
     *  Use Mounted (returns ifMounted)
     *  On location change run useEffect
     *  Init functions and components
     *  Render
     */

    const subsiteMenuRef = useRef()
    const subsiteTriggerRef = useRef()

    const parentSiteMenuRef = useRef()
    const parentSiteTriggerRef = useRef()

    const settingsDropdownRef = useRef()
    const settingsDropdownTriggerRef = useRef()

    const history = useHistory()

    // Get site id from url 
    let currentSiteId = (decodeSearchParams())?.siteId ?? undefined

    // If site id is not found in url, then use default site id
    if (currentSiteId === undefined) {
        currentSiteId = ApiManager.session.getSiteId()
    }
    
    const [state, setState] = useState({site: {id: currentSiteId}, subSites: [], parentSites: [], siteId: currentSiteId, openNav: false})
    const [isMounted, setMounted] = useState(false)

    const [notification, setNotification] = useState(false)

    /**
     * Find if there is a websocket notification
     * @param {object} notifications websocket notification object
     * @returns {boolean} true or false
     */
    const hasNotification = (notifications) => {
        /**
         * Pseudo Code
         *  get keys from notification object
         *  If keys exist
         *      For each key
         *          if the key has a count value
         *              return true
         *  return false
         */
        let keyArr = Object.entries(notifications)

        if (keyArr.length > 0) {
            for (const key in notifications) {
                if (notifications[key].count) {
                    return true
                }
            }
        }
        
        return false
    }

    /**
     * Event handler function for when socket state updates
     */
    const socketStateUpdated = () => {
        /**
         * Pseudo Code
         *  initialize notifications from socket state triggers
         *  check for notification
         *  set notification state
         */
        let notifications = ApiManager.wsManager.socketState.triggers
        let notify = hasNotification(notifications)
        setNotification(notify)
    }

    /**
     * @description Takes in a site id and sets the state to match the siterequest
     * @param {number} siteId id of a user site 
     */
    const getSites = async (siteId) => {
        /**
         * Pseudo Code
         *  Loading start
         *  Get path
         *  Init apiData
         *  If path valid recieved
         *      Transform path
         *  Else
         *      Site not found
         *      Set api data to unknown site
         *  Init sites subsites and result
         *  If parents is not empty 
         *      push parent sites to array
         *  Push current site
         *  Set state
         *  Loading Done
         */

        // Loading start
        props.loading.loadStart()
        
        // Api call 
        let res = await ApiManager.router.sites.getPath(siteId)
        
        let apiData

        // If path valid recieved
        if (res.isSuccess) {           
            apiData = transformPath(res.data)
        }
        else {
            // Send error code
            props.history.push({pathname: "/sitenotfound", search: props.history.location.search})
            apiData = {
                siteId: "Site Not Found",
                name: "Site Not Found",
                parentSites: [],
                subSites: []
            }
        }

        // Init sites subsites and result
        let subSites = []
        let parentSites = []
        let site
        let result = apiData

        // Set current site
        site = {id: result.siteId, name: result.name}

        // Push all parent sites to parent sites array
        for (let i = 0; i < result.parentSites.length; i++) {
            parentSites.push({id: result.parentSites[i].Id, name: result.parentSites[i].Name})
        }

        // Push all subsites to subsite array
        for (let i = 0; i < result.subSites.length; i++) {
            subSites.push({id: result.subSites[i].Id, name: result.subSites[i].Name})
        }    

        if (isMounted === true) {
            // Set state
            setState({...state, site: site, siteId: result.siteId, subSites: subSites, parentSites: parentSites})
        }

        // Loading Done
        props.loading.loadDone()
    }

    const previousLocation = useRef()

    // On mount
    useEffect(() => {
        // Set mounted to true
        setMounted(true)
        ApiManager.wsManager.socketState.stateUpdated.addListener(socketStateUpdated)

        // On unmount
        return () => {
            // Set unmounted to true
            setMounted(false)
            ApiManager.wsManager.socketState.stateUpdated.removeListener(socketStateUpdated)
        }
    }, [])

    // On mounted
    useEffect(() => {
        if (isMounted === true) {
            getSites(currentSiteId)
        }
    }, [isMounted])

    // On location change
    useEffect(() => {
        /**
         * Psuedo Code
         *  Get previous search
         *  Get current search
         *  Get previous site id
         *  Get current site id
         *  Let update be false
         *  If siteId is not equal to prev site id
         *      Start loading
         *      Set update flag true
         *  If location state mode is refresh topbar
         *      Start loading
         *      Set update to true
         *  If update flag is true
         *      If site id is undefined
         *          Get default site id
         *      If component is mounted get sites
         */

        const previousSearch = decodeSearchParams(previousLocation?.current?.search ?? '')
        const search = decodeSearchParams()
        const prevSiteId = previousSearch.siteId
        let siteId = search.siteId

        let update = false

        if (siteId !== prevSiteId) {
            update = true
        }

        if (props.location.state && props.location.state.mode === 'refreshTopBar') {
            props.loading.loadStart()
            update = true
        }

        if (update === true) {
            // If site id is not found in url, then use default site id
            if (siteId === undefined) {
                siteId = ApiManager.session.getSiteId()
            }

            if (isMounted === true) {
                getSites(siteId)

            }

            ApiManager.session.setCurrentSiteId(siteId)
        }

        previousLocation.current = props.location
    }, [props.location])

    /**
     * Set menu postion to position of trigger
     * @param {Ref} triggerRef Menu trigger ref
     * @param {Ref} menuRef Menu ref
     */
    const setMenuPosition = (triggerRef, menuRef) => {
        /**
         * Pseudo Code
         *  Get menu trigger
         *  If menu style is block
         *      set menu style to none
         *  Else
         *      set menu style to block
         *      Get window width
         *      Get trigger
         *      Set menu top
         *      Set menu left to trigger x position
         *          Get bounding menu
         *      If menu is out of bounds
         *          Set menu left to auto
         *          Set menu right 0px
         */

        // Get menu from trigger
        let menu = menuRef.current
        
        if (menu.style.display === 'block') {
            // Set display to none
            menu.style.display = 'none'
        }
        else {
            // Set display to block
            menu.style.display = 'block'

            // Get window width
            let windowWidth = props.dimensions.width

            // Get trigger
            let trigger = triggerRef.current
            let triggerBounds = trigger.getBoundingClientRect()
            let icon = trigger.querySelector('.caret.down')
            let iconBounds = icon.getBoundingClientRect()

            // Set menu height
            menu.style.top = ((iconBounds.y) + iconBounds.height - 2) + 'px'

            // Get menu left
            menu.style.left = triggerBounds.x + 'px'

            // Get menu bounds
            let menuBound = menu.getBoundingClientRect()
        
            // If menu is out of bounds
            if (windowWidth < (menuBound.x + menuBound.width)) {
                // Set menu left to auto
                menu.style.left = 'auto'

                // Set menu right to auto
                menu.style.right = '0px'
            }
        }
    }

    /**
     * Set settings menu postion to position of trigger
     * @param {Ref} triggerRef Menu trigger ref
     * @param {Ref} menuRef Menu ref
     */
    const setSettingsMenuPosition = (triggerRef, menuRef) => {
        /**
         * Pseudo Code
         *  Get menu trigger
         *  If menu style is block
         *      set menu style to none
         *  Else
         *      set menu style to block
         *      Get window width
         *      Get trigger
         *      Set menu left to trigger x position
         *      Get bounding menu
         *      If menu is out of bounds
         *          Set menu left to auto
         *          If window width is less than 850px
         *              Get icon trigger
         *              Set menu top
         *              If window is less than 550px
         *                  set menu right
         *              Else window is between 850-550px
         *                  set menu right
         *          Else
         *              Get icon trigger
         *              set menu top
         *              set menu right
         */

        // Get menu from trigger
        let menu = menuRef.current
        
        if (menu.style.display === 'block') {
            // Set display to none
            menu.style.display = 'none'
        }
        else {
            // Set display to block
            menu.style.display = 'block'

            // Get window width
            let windowWidth = props.dimensions.width

            // Get trigger
            let trigger = triggerRef.current
            let triggerBounds = trigger.getBoundingClientRect()

            // Get menu left
            menu.style.left = triggerBounds.x + 'px'

            // Get menu bounds
            let menuBound = menu.getBoundingClientRect()
        
            // If menu is out of bounds
            if (windowWidth < (menuBound.x + menuBound.width)) {
                // Set menu left to auto
                menu.style.left = 'auto'

                if (windowWidth <= 850) {
                    let icon = trigger.querySelector('.setting.icon')
                    let iconBounds = icon.getBoundingClientRect()

                    // Set menu height
                    menu.style.top = ((iconBounds.y) + iconBounds.height + 2) + 'px'

                    if (windowWidth <= 550) {
                        // Set menu right to auto
                        menu.style.right = '8px'
                    }
                    else {
                        // Set menu right to auto
                        menu.style.right = '12px'
                    }
                }
                else {
                    let icon = trigger.querySelector('.caret.down')
                    let iconBounds = icon.getBoundingClientRect()

                    // Set menu height
                    menu.style.top = ((iconBounds.y) + iconBounds.height + 2) + 'px'

                    // Set menu right to auto
                    menu.style.right = '12px'
                }
            }
        }
    }

    /**
     * Handle on select site event
     * @param {Event} e On select site event 
     * @param {Object} data Item props 
     */
    const handleSelectSite = (e, data) => {
        /**
         * Pseudo Code
         *  Get menu ref
         *  Set menu display to none
         *  Run on click from props if available
         */

        // Get menu ref
        const menu = data.menuRef.current

        // Close menu
        menu.style.display = 'none'
    }

    /**
     * Handle on subsite menu click
     */
    const handleSubsiteMenuClick = () => {
        setMenuPosition(subsiteTriggerRef, subsiteMenuRef)
    }

    /**
     * Handle on subsite menu blur
     * @param {Event} e On menu blur event
     */
    const handleSubsiteMenuBlur = (e) => {
        /**
         * Pseudo Code
         *  Get related target
         *  If related target is not null and attribute doesn't exist
         *      Get menu ref
         *      Close menu
         */

        // Get related target
        const {relatedTarget} = e

        // If related target is not null and attribute doesn't exist
        if (relatedTarget === null || (relatedTarget.getAttribute instanceof Function && relatedTarget.getAttribute('data-subsite-item') === null)) {
            // Get menu from trigger
            let menu = subsiteMenuRef.current

            // Hide menu
            menu.style.display = 'none'
        }
    }

    /**
     * Handle on parent site menu click
     */
    const handleParentMenuClick = () => {
        setMenuPosition(parentSiteTriggerRef, parentSiteMenuRef)
    }

    /**
     * Handle on parent site menu blur
     * @param {Event} e On menu blur event
     */
    const handleParentMenuBlur = (e) => {
        /**
         * Pseudo Code
         *  Get related target
         *  If related target is not null and attribute doesn't exist
         *      Get menu ref
         *      Close menu
         */

        // Get related target
        const {relatedTarget} = e

        // If related target is not null and attribute doesn't exist
        if (relatedTarget === null || (relatedTarget.getAttribute instanceof Function && relatedTarget.getAttribute('data-parentsite-item') === null)) {
            // Get menu from trigger
            let menu = parentSiteMenuRef.current

            // Hide menu
            menu.style.display = 'none'
        }
    }

    /**
     * Handle Settings Dropdown menu click
     */
    const handleSettingsDropdownClick = () => {
        setSettingsMenuPosition(settingsDropdownTriggerRef, settingsDropdownRef)
    }

    /**
     * Handle on settings dropdown menu blur
     * @param {Event} e On menu blur event
     */
    const handleSettingsDropdownBlur = (e) => {
        const {relatedTarget} = e
       
        // If related target is not null and attribute doesn't exist
        if (relatedTarget === null || (relatedTarget.getAttribute instanceof Function && relatedTarget.getAttribute('data-settings-item') === null)) {
            // Get menu from trigger
            let menu = settingsDropdownRef.current

            // Hide menu
            menu.style.display = 'none'
        }
    }

    /**
     * Handle on setting click event
     * @param {Event} e On select setting event 
     * @param {Object} data Item props 
     */
    const handleSettingsLinkClick = (e, data) => {
        // Get menu ref
        const menu = data.menuRef.current

        // Close menu
        menu.style.display = 'none'
    }

    /**
     * On log out event
     * @param {Event} e On select log out event
     * @param {Object} data Item props
     */
    const logOut = async (e, data) => {  
        // Get menu ref
        const menu = data.menuRef.current

        // Close menu
        menu.style.display = 'none'

        // Logout user
        await ApiManager.session.destroySession()
    }

    /**
     * On go to root site event
     * @param {Event} e On select go to root site event
     * @param {Object} data Item props
     */
    const goToRootSite = (e, data) => {  
        // Get menu ref
        const menu = data.menuRef.current

        // Close menu
        menu.style.display = 'none'

        // Existing url params
        let urlQueryParams = decodeSearchParams()

        if (urlQueryParams?.siteId !== undefined) {
            // delete site id from url param object
            delete urlQueryParams.siteId
        }

        // update search url params
        history.push({search: encodeSearchParams({...urlQueryParams})})
    }
    

    /**
     * If on historical or live sensor data page redirect user to devices
     */
    const getNewPath = () => {
        /**
         * Pseudo Code
         *  If current path is historical sensor data
         *      return devices
         *  If current path is live sensor data
         *      return devices
         *  If current path is report alerts
         *      return reports
         *  If current path is monthly report
         *      return reports
         *  If current path is weekly report
         *      return report
         *  Else
         *      return null
         */
        
        if (props.history.location.pathname === '/devices/historical-sensor-data') {
            return '/devices'
        }
        else if (props.history.location.pathname === '/devices/live-sensor-data') {
            return '/devices'
        }
        else if (props.history.location.pathname === '/reports/alerts') {
            return '/reports'
        }
        else if (props.history.location.pathname === '/reports/monthly-report') {
            return '/reports'
        }
        else if (props.history.location.pathname === '/reports/weekly-report') {
            return '/reports'
        }
        else {
            return null
        }
    }

    // Current site
    const currentSite = state.site
    
    // Current subsites
    const subsites = state.subSites
    
    // Current parent sites
    const parentSites = state.parentSites
    
    // Current parents and site
    const sites = [...parentSites, currentSite]

    // Subsite menu trigger
    const subsiteMenuTrigger = (
        <span className='subsiteTrigger' tabIndex={3} ref={subsiteTriggerRef} onClick={handleSubsiteMenuClick} onBlur={handleSubsiteMenuBlur}>
            <Icon name="caret down" /> 
        </span>
    )

    // Parent menu trigger
    const parentMenuTrigger = (
        <span className='parentSiteTrigger' tabIndex={4} ref={parentSiteTriggerRef} onClick={handleParentMenuClick} onBlur={handleParentMenuBlur}>
            <Icon name="caret right" /> 
            <Icon name="caret down" /> 
        </span>
    )

    // Settings menu trigger
    const settingsDropdownTrigger = (
        <div className='settingsMenu' tabIndex={1} ref={settingsDropdownTriggerRef} onClick={handleSettingsDropdownClick} onBlur={handleSettingsDropdownBlur} >
            <Icon name="setting" />
            <span>
                {" "}
                {"SETTINGS "}
            </span>
            <Icon name="caret down" /> 
        </div>
    )

    // Sort subsites
    subsites.sort((a, b) => {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ) {
            return -1
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ) {
            return 1
        }
        return 0
    })

    const newPath = getNewPath()
    const defaultSiteId = ApiManager.session.getSiteId("default")
    
    return (
        <div className="mainHeaderBar" style={{position: 'relative'}}>
            <div className="mainHeaderBarLeft transparent" style={{ height: "100%", width: 'fit-content'}}>
                <div className="navMenu-header">
                    <svg className="navMenu-logo" width="40" height="40" viewBox="0 0 325 325">
                        <defs>
                            <mask id="hole">
                                <rect width="100%" height="100%" fill="white" />
                                <circle cx="36" cy="167" r="15" />
                                <circle fill="black" cx="87.5" cy="166.5" r="19.5" />
                                <circle fill="black" cx="151" cy="163.5" r="27" />
                                <path fill="black" d="M194,135l12-12s20.414,20.411,20,46c-0.519,32.061-21,49-21,49l-12-13s16.1-16.013,16-36C208.9,148.849,193.984,135.063,194,135Z" transform="translate(-7 -7)" />
                                <path fill="black" d="M219,112l13-14s29,28.814,29,70.362C261,213.567,232,244,232,244l-14-14s24.137-28.256,24-61C241.862,135.986,218.979,112.1,219,112Z" transform="translate(-7 -7)" />
                                <path fill="black" d="M245,87l15-15s36,36.747,36,95c0,62.154-37,101-37,101l-17-14s32-35.665,32-88C274,118.408,244.967,87.149,245,87Z" transform="translate(-7 -7)" />
                            </mask>
                        </defs>
                        <circle className='logo' r="162.5" cx="162.5" cy="162.5" mask="url(#hole)" />
                    </svg>
                    {" "}
                    <span className="navMenu-title">
                        <EotLive />
                    </span>
                </div>
                <div className="navMenu-menuBtn">
                    <i className="fas fa-bars" onClick={props.toggleNav}>
                        { notification && <Notification className='menuBtnNotification' showCount={false} />}
                    </i>
                </div>
                <div className="vSpacer"></div>
            </div>
            <div className="mainHeaderBarMiddle transparent">
                <div className="pathText" onClick={props.onClick} tabIndex={null}>
                    {
                        sites.map((text, index) => {
                            return (
                                <Link key={index} tabIndex={-1} to={{...(newPath !== null && {pathname: newPath}), search: encodeSearchParams({...(decodeSearchParams() ?? {}), searchFilters: undefined, deviceId: undefined, ...((defaultSiteId === text.id) ? {siteId: undefined} : {siteId: text.id})})}} data-site-id={text.id} onClick={(index === (sites.length - 1)) ? (event) => event.preventDefault() : null}>
                                    <span className="crumb" style={{ ...(index === (sites.length - 1) ? { cursor: "default" } : { cursor: "pointer" }) }}> 
                                        {text.name} 
                                        { index < sites.length - 1 && <Icon name="caret right" /> }
                                    </span>
                                </Link>
                            )
                        })  
                    }
                    <Loader style={{ position: "relative", marginTop: "-10px", top: "22px", marginLeft: "32px", left: "0px"}} active={props.isLoading}></Loader>
                </div>
                {(props.isLoading === false && parentSites !== undefined && parentSites.length !== 0) && parentMenuTrigger}
                <div className="pathTextMobile" onClick={props.onClick} style={{marginRight: 0}}>
                    {
                        (props.isLoading === false && currentSite?.name) ?
                            <Link to={{search: encodeSearchParams({...decodeSearchParams(), searchFilters: undefined, deviceId: undefined, ...(defaultSiteId === currentSite.id ? {siteId: undefined} : {siteId: currentSite.id})})}} data-site-id={currentSite.id}>
                                <span className="crumb" > 
                                    {" " + currentSite.name + " "} 
                                </span>
                            </Link>
                            :
                            <Loader size='small' style={{ position: "relative", marginTop: "-10px", top: "18px", marginLeft: "16px", left: "0px"}} active={true}></Loader>
                    }
                </div>
                {props.isLoading === false && subsites !== undefined && subsites.length !== 0 && subsiteMenuTrigger}
            </div>
            <div className='mainHeaderBarRight transparent'>
                {
                    // TODO uncomment for websocket status indicator icon 
                    // props.websocketStatus === true ? <i className="fa-solid fa-circle" style={{color: 'green'}}></i> : <i className="fa-solid fa-circle" style={{color: 'red'}}></i>
                }
            </div>
            <div className="mainHeaderBarRight transparent">
                {props.isLoading === false && props.dropdownLinks !== undefined && settingsDropdownTrigger}
            </div>
            <Ref innerRef={subsiteMenuRef}>
                <Menu className='topBarMenu' vertical tabIndex={3}>
                    {
                        subsites.map(subSite => {
                            let search = (decodeSearchParams()) ?? {}
                            search.siteId = subSite.id
                            delete search.searchFilters
                            delete search.deviceId
                            
                            return (
                                <Menu.Item key={subSite.name} tabIndex={3} data-subsite-item={true} as={Link} onClick={(e, data) => handleSelectSite(e, {...data, menuRef: subsiteMenuRef})} text={subSite.name} data-site-id={subSite.id} to={{...(newPath !== null && {pathname: newPath}), search: encodeSearchParams(search)}}>
                                    {subSite.name}
                                </Menu.Item>
                            )
                        })
                    }
                </Menu>
            </Ref>
            <Ref innerRef={parentSiteMenuRef}>
                <Menu className='topBarMenu' vertical tabIndex={4}>
                    {
                        parentSites.map(site => {
                            let search = decodeSearchParams() ?? {}
                            let siteId = ""
                            
                            // If text id is the default site id search query should be empty
                            if (ApiManager.session.getSiteId("default") === site.id) {
                                delete search.siteId
                            }
                            else {
                                search.siteId = site.id
                                siteId = site.id
                            }
                
                            return(
                                <Menu.Item key={site.name} tabIndex={4} as={Link} text={site.name} data-parentsite-item={true} data-site-id={siteId} to={{...(newPath !== null && {pathname: newPath}), search: encodeSearchParams(search)}} onClick={(e, data) => handleSelectSite(e, {...data, menuRef: parentSiteMenuRef})}>
                                    {site.name}
                                </Menu.Item>
                            )
                        })
                    }
                </Menu>
            </Ref>
            <Ref innerRef={settingsDropdownRef}>
                <Menu className='topBarMenu settings' vertical tabIndex={1}>
                    {
                        decodeSearchParams()?.siteId !== undefined && 
                            <Menu.Item tabIndex={1} data-settings-item={true} onClick={(e, data) => goToRootSite(e, {...data, menuRef: settingsDropdownRef})} >
                                <div className='settingsMenuItem'>
                                    <Icon name='home' />
                                    {" "}
                                    <span>
                                        GO TO ROOT SITE
                                    </span>
                                </div>
                            </Menu.Item>
                    }
                    {
                        props.dropdownLinks.map((link) => {
                            let {siteId} = decodeSearchParams()
                            
                            let search = {
                                ...(siteId !== undefined && {siteId: siteId})
                            }

                            return (
                                <Menu.Item key={link.name} tabIndex={1} as={Link} data-settings-item={true} to={{pathname: link.route, search: encodeSearchParams(search)}} onClick={(e, data) => handleSettingsLinkClick(e, {...data, menuRef: settingsDropdownRef})} >
                                    <div className='settingsMenuItem'>
                                        <i className={`${link.icon} icon`} />
                                        <span>
                                            {(link.altName ?? link.name ?? '').toUpperCase()}
                                        </span>
                                    </div>
                                </Menu.Item>
                            )
                        })
                    }
                    <Menu.Item tabIndex={1} data-settings-item={true} onClick={(e, data) => logOut(e, {...data, menuRef: settingsDropdownRef})} >
                        <div className='settingsMenuItem'>
                            <Icon name='log out' />
                            <span>
                                LOG OUT
                            </span>
                        </div>
                    </Menu.Item>
                </Menu>
            </Ref>
        </div> 
    )
}

export default withRouter(WindowSize(TopBar))