import React from "react"
import { Grid } from 'semantic-ui-react'
import EotLive from "../components/EotLive"


/**
 * Component for centering a Glass Box component
 * 
 * @param {Object} props 
 * @param {Object} props.children Glass Box component
 * @param {Object} props.height hight of glass box, fixes scroll if scroll is need
 * @param {Object} props.maxWidth max width of glass box 
 */
function GlassHolder(props) {
    return (
        <div style={{width: '100%', height: '100vh', overflowY: 'auto', overflowX: 'hidden', display: 'flex'}}>  
            <div className="glassHolder">
                <div className="rootHeader" style={{position: 'relative', zIndex: 1000, width: '100%', justifyContent: 'center', marginTop: '16px'}}>
                    <svg className="rootLogo" width="40" height="40" viewBox="0 0 325 325">
                        <defs>
                            <mask id="hole">
                                <rect width="100%" height="100%" fill="white" />
                                <circle fill="black" cx="36" cy="167" r="15" />
                                <circle fill="black" cx="87.5" cy="166.5" r="19.5" />
                                <circle fill="black" cx="151" cy="163.5" r="27" />
                                <path fill="black" d="M194,135l12-12s20.414,20.411,20,46c-0.519,32.061-21,49-21,49l-12-13s16.1-16.013,16-36C208.9,148.849,193.984,135.063,194,135Z" transform="translate(-7 -7)" />
                                <path fill="black" d="M219,112l13-14s29,28.814,29,70.362C261,213.567,232,244,232,244l-14-14s24.137-28.256,24-61C241.862,135.986,218.979,112.1,219,112Z" transform="translate(-7 -7)" />
                                <path fill="black" d="M245,87l15-15s36,36.747,36,95c0,62.154-37,101-37,101l-17-14s32-35.665,32-88C274,118.408,244.967,87.149,245,87Z" transform="translate(-7 -7)" />
                            </mask>
                        </defs>
                        <circle className='logo' r="162.5" cx="162.5" cy="162.5" mask="url(#hole)" />
                    </svg>
                    <span className="navMenu-title">
                        <EotLive size="medium" />
                    </span>
                </div>
                <Grid textAlign='center' stretched verticalAlign='middle' style={{ background: "inherit", paddingTop: "20px", paddingBottom: "20px", minHeight: props.height }}>
                    <Grid.Column style={{ maxWidth: props.maxWidth, background: "inherit"}}>
                        { props.children }
                    </Grid.Column>
                </Grid>
            </div>
        </div>
    )
}

export default GlassHolder