/**
 * Class for routing REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file index.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/

import { io } from "socket.io-client"
import Alerts from "./alerts"
import EventListener from "../../utilities/eventUtility"

/**
 * Class for routing REST request
 */
class WebSocket {
    constructor() {
        this.connected = false

        this.clientHolder = {
            ioClient: null
        }

        this.onConnectListener = new EventListener()
        this.onDisconnectListener = new EventListener()
        this.onJoinRoomListener = new EventListener()
        this.onLeaveRoomListener = new EventListener()

        this.alerts = new Alerts(this.clientHolder)
    }

    /**
     * Initialize request router 
     * @param {class} session Handle session values, such as permissions and site ids
     */
    initialize(session) {
        this.clientHolder.ioClient = io("", {
            path: "/ws/",
            auth: (cb) => {
                cb({
                    token: session.getAccessToken()
                })
            }
        })

        // handle on connect
        this.clientHolder.ioClient.on("connect", () => {           
            this.onConnectListener.dispatchListeners()
        })

        // handle on disconnect
        this.clientHolder.ioClient.on("disconnect", () => {
            this.onDisconnectListener.dispatchListeners()
        })

        // handle on join room
        this.clientHolder.ioClient.on("join", (msg) => {
            this.onJoinRoomListener.dispatchListeners(msg)
        })

        // handle on leave room
        this.clientHolder.ioClient.on("leave", (msg) => {
            this.onLeaveRoomListener.dispatchListeners(msg)
        })
    }

    /**
     * Disconnect socket client
     */
    terminate() {
        this.clientHolder.ioClient.disconnect()
    }
}

export default WebSocket