/**
 * Portal Component
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file portal.js
 * @author Kyle Watkins, Matt Schreider, Paul Scala
 */

import React, { Suspense } from "react"
import { Switch, Route, Redirect, withRouter } from "react-router-dom"
import TopBar from "./components/TopBar"
import { SleepAsync } from "./utilities/sleep"
import { Loader, Transition } from "semantic-ui-react"
import PageTitle from "./stateless/PageTitle"
import NavBar from "./components/NavBar"
import ErrorCatcher from "./components/ErrorCatcher"
import ApiManager from "./api/ApiManager"

/**
 * Portal Routing Component
 * @description Returns the Portal Component
 * @param {Object} props Properties of Loading
 * @param {object[]} props.pages Portal pages to build out
 * @param {object[]} props.navBarLinks Navbar links to build out
 * @param {object[]} props.dropdownLinks Dropdown links to build out
 * @usage Add pages to this.state.links for navigation
 */
class Portal extends React.PureComponent {
    /**
     * @description Place pages here in state.links
     * @objectstructure {name : "pagename", icon: "iconname" OR faIcon: "faIconname", component: pagecomponent, onMain: null or false(is on main nav?)}   
     */
    constructor(props) {
        super(props)
        // Set State
        this.state = {
            started: false,
            showNav: false,
            // Set Loading True
            loading: true,
            websocketStatus: false,
        }
    }
    
    componentDidMount = async () => {
        /**
         * Pseudo Code
         *  Wait 10ms
         *  Then start first load
         */
        
        // Wait
        await SleepAsync(10)

        ApiManager.ws.onConnectListener.addListener(this.websocketConnected)
        ApiManager.ws.onDisconnectListener.addListener(this.websocketDisconnected)

        // Start first load
        if (this.state.started === false) {
            this.firstStart()
        }
    }

    componentWillUnmount = () => {
        ApiManager.ws.onConnectListener.removeListener(this.websocketConnected)
        ApiManager.ws.onDisconnectListener.removeListener(this.websocketDisconnected)
    }

    /**
     * Handle websocket is connected to portal
     */
    websocketConnected = () => {
        this.setState({
            websocketStatus: true
        })
    }

    /**
     * Handle websocket disconnected to portal
     */
    websocketDisconnected = () => {
        this.setState({
            websocketStatus: false
        })
    }

    /**
     * Show nav
     */
    navOn = () => {
        this.setState({showNav: true})
    }

    /**
     * Close nav
     */
    navOff = () => {
        this.setState({showNav: false})
    }

    /**
     * Toggle nav
     */
    toggleNav = () => {
        /**
         * Pseudo Code
         *  If nav is on
         *      switch off
         *  else
         *      switch on
         */

        if (this.state.showNav) {
            this.navOff()
        }
        else {
            this.navOn()
        }
    }

    /**
     * @description Navigation animation
     */
    firstStart = () => {       
        this.setState({
            started: true
        })
    }

    /**
     * @description Show Loading Screen
     */
    loadingStart() {
        this.that.setState({loading: true })
    }
    
    /**
     * @description Close Loading Screen
     */
    loadingDone() {  
        this.that.setState({loading: false })
    }

    /**
     * @description Object to handle the loading screen
     * @param {function} loadDone (Function) Turn off loading screen
     * @param {function} loadStart (Function) Turn on loading screen
     * @param {component} that The component where the loader sits
     * @note Pass this object through a component. (e.g. loading=this.loading)
     */
    loading = {
        loadDone: this.loadingDone, 
        loadStart: this.loadingStart, 
        that: this 
    }

    render() {
        /**
         * Pseudo Code
         *  Get links
         *  Init alternative links
         *  Generate page for each link
         *  Return Portal Component 
         */
        
        const links = this.props.pages
        const alt = []

        let pages = links.filter(link => link.route !== undefined).map((link) => {
            let path = link.route
            if (link.all === true) {
                path = '*'
            }

            let alternativeRoutes = link?.alternativeRoutes ?? []
            alternativeRoutes.map(altRoute => {
                alt.push(
                    <Route key={altRoute} path={altRoute}>
                        <Redirect to={link.route} />
                    </Route>
                )
            })

            return (
                <Route key={link.name} exact path={path}> 
                    <Suspense fallback={
                        <div className="viewer bg">
                            <Loader active />
                        </div>
                    }
                    >
                        <ErrorCatcher>
                            <PageTitle title={path}>
                                <link.component name={link.name} displayName={link.displayName ?? link.name} route={path} icon={link.icon} faIcon={link.faIcon} />
                            </PageTitle>
                        </ErrorCatcher>
                    </Suspense>
                </Route>
            )
        })

        return (
            <div className="imageHolder">
                <div className="full glass">
                    <Transition animation="fade" duration={1000} transitionOnMount >
                        <div className="blurred">
                            <div style={{background: "inherit"}}>
                                <TopBar isLoading={this.state.loading} loading={this.loading} started={this.state.started} toggleNav={this.toggleNav} dropdownLinks={this.props.dropdownLinks} websocketStatus={this.state.websocketStatus} />
                                <NavBar closeNav={this.navOff} openNav={this.state.showNav} started={this.state.started} navBarLinks={this.props.navBarLinks} />
                            </div>
                            <div className="main">
                                <div className={"page-container" + ((!this.state.started) ? " loading" : "")}>
                                    <Switch>
                                        {
                                            pages
                                        }
                                        {
                                            alt
                                        }
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>  
        )
    }
}

export default withRouter(Portal)