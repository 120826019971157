/**
 * Singleton class for managing web request using Axios
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file ApiManager.js
 * @author Kyle Watkins, Paul Scala, Matt Schreider
 */

import axios from "axios"
import Router from "./router"
import SessionManager from "./SessionManager"
import WebSocketManager from "./WebSocketManager"
import WebSocket from "./webSockets/index"

/**
 * Singleton class for managing web request using Axios  
 */
class ApiManager {
    constructor() {
        // Setup axios instance
        this.axiosInstance = axios.create({timeout: 10000})
        this.axiosRefreshInstance = axios.create()

        this.callback = {}
        
        // Add axios authorization interceptor
        this.axiosInstance.interceptors.request.use(config => {
            const token = this.session.getAccessToken()

            // If not an /auth unless /auth/refresh or /auth/login
            if (!config.url.includes("/api/auth") || (config.url.includes("/api/auth/refresh") || config.url.includes("/api/auth/logout"))) {
                // If token is available
                if (token && token !== "") {
                    config.headers.Authorization = `Bearer ${token}`
                }
                /*
                else {
                    // If token is not available cancel the request
                    return {
                        ...config,
                        cancelToken: new axios.CancelToken((cancel) => cancel('Cancel repeated request'))
                    }
                }*/
            }
            
            return config
        },
        error => {
            Promise.reject(error)
        })

        // Setup session manager
        this.session = new SessionManager()

        // Setup websocket
        this.ws = new WebSocket()

        // setup websocket manager
        this.wsManager = new WebSocketManager(this.ws, this.session)

        // Add axios interceptor for handling session refreshing when forbidden and other error codes
        this.axiosInstance.interceptors.response.use(response => {
            return (response)
        },
        async (error) => {
            // If cancelled request
            if (axios.isCancel(error)) {
                // Logout of broken session
                await this.session.destroySession()

                // Here you check if this is a cancelled request to drop it silently (without error)
                return new Promise(() => {})
            }

            if (error.response !== undefined) {
                // Get error status code
                let status = error.response.status

                // Get orignal request config
                let originalRequest = error.config
                originalRequest.headers.Authorization = true
                
                // Get refresh token
                let refreshToken = this.session.getRefreshToken()
                let accessToken = this.session.getAccessToken()

                // If request is Forbidden and there exist a refreshToken
                if (status === 401 && refreshToken) {
                    // Set request data
                    let data = {
                        RefreshToken: refreshToken
                    }

                    // Refresh token config
                    let config = {
                        method: 'post',
                        url: '/api/auth/refresh',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        },
                        retry: 1,
                        data: JSON.stringify(data)
                    }

                    try {
                        // Try to get tokens
                        let res = await this.axiosRefreshInstance(config)

                        // Create tokens
                        this.session.setTokens(res.data)

                        return (this.axiosInstance(originalRequest))
                    }
                    catch {
                        // Erase broken tokens
                        this.session.deleteTokens()

                        // Logout of broken session 
                        await this.session.destroySession()

                        // Drop request silently
                        return (error.response)
                    }
                }
                
                return (error.response)    
            }

            // Return a timeout error
            return ({
                status: 504,
                isSuccessful: false,
                statusText: "Timeout",
                data: {
                    ErrorCode: 504, 
                    ErrorMessage: "Timeout"
                }
            })
        })

        // Setup router
        this.router = new Router(this.axiosInstance, this.session)       

        // Add Router to session
        this.session.addRouter(this.router)

        // Add Websocket Manager to session
        this.session.addWebSocketManager(this.wsManager)

        // Add Session to web socket manager
        this.wsManager.addSession(this.session)

        this.session.addCreateSessionListener(() => {
            this.wsManager.initialize()
        })

        this.session.addDestroySessionListener(() => {
            this.wsManager.terminate()
        })
    }

    /**
     * @description EventListener for ApiManager
     * @param {object} event Event to listen for 
     * @param {function} func Function to run when event is triggered
     */
    EventListener(event, func) {
        this.callback[event] = func
        this.session.setEventListener(this.callback)
    }
}

export default new ApiManager()