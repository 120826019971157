/**
 * Nav Bar React Component
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file NavBar.js
 * @author Kyle Watkins, Matt Schreider, Paul Scala
 */

import React from 'react'
import NavItem from './NavItem'
import { withRouter } from 'react-router-dom'
import '../resources/css/navigation-menu.css'
import { WindowSize } from '../stateless/WindowSize'
import ApiManager from '../api/ApiManager'

/**
 * @description NavBar Component 
 * @param {Object} props Component Properties
 * @param {number} props.siteId Current siteId
 * @param {boolean} props.started Application has opened
 */
class NavBar extends React.Component {
    // Keep For Animating Later
    constructor(props) {
        super(props)

        this.state = {
            notifications: {},
        }
    }

    componentDidMount() {
        ApiManager.wsManager.socketState.stateUpdated.addListener(this.socketStateUpdated)
    }

    componentWillUnmount = () => {
        ApiManager.wsManager.socketState.stateUpdated.removeListener(this.socketStateUpdated)
    }

    /**
     * Handle socket state update
     */
    socketStateUpdated = () => {
        /**
         * Pseudo Code
         *  initialize notifications from socket state triggers
         *  set state
         */
        let notifications = ApiManager.wsManager.socketState.triggers

        this.setState({
            notifications: notifications,
        })
    }

    render() {
        let links = this.props.navBarLinks
        
        // Return Inner Component
        return (
            <div className={"navigationMenu" + ((!this.props.started) ? " loading" : "") + ((this.props.openNav && this.props.dimensions['width'] < 850) ? " active" : "")}>
                <div className="navMenuItem-grid">
                    {   
                        // For each link map a NavItem
                        links.map(link => {
                            let notifications = undefined
                            if (this.state.notifications[link.name]) {
                                notifications = this.state.notifications[link.name]
                            }
                            
                            return (
                                <NavItem key={link.name} name={link.name.toUpperCase().replace("-", " ")} faIcon={link.faIcon} icon={link.icon} siteId={this.props.siteId} location={this.props.location} onClick={this.props.closeNav} notifications={notifications} />
                            )
                        })
                    }
                </div>            
            </div>  
        )
    }
}

export default withRouter(WindowSize(NavBar))