/**
 * Global themes object
 * @copyright 2021-2023 Soter Technologies, LLC. All rights reserved.
 * @file themes.js
 * @author Kyle Watkins, Matt Schreider, Paul Scala
 */

/**
 * Global themes object
 */
const Themes = {
    'Default': { key: 6, text: 'Default', value: "Default" },
    'Auto': { key: 5, text: 'Auto', value: "Auto" },
    'Dark': { key: 1, text: 'Dark', value: "Dark" },
    'Light': { key: 2, text: 'Light', value: "Light" },
    ...(process.env.CustomThemes === true && {
        'Glacier': { key: 3, text: 'Glacier', value: "Glacier" },
        'Matrix': { key: 4, text: 'Matrix', value: "Matrix" },
    })
}

export default Themes