/**
 * Awaitable function for delaying execution 
 * 
 * @param {number} ms Number of milliseconds to sleep for
 * @returns Promise
 */
const SleepAsync = (ms) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, ms)
    })
}

export { SleepAsync }