/**
 * Class for handling system messages REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file systemmessages.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/

class SystemMessages {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * @description Get System Messages
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchfilters.severity Fitler by severity 
     * @returns {ResponseObject} Api response object 
     */
    async getSystemMessages(siteId, {page = 0, severity = "All"} = {}) {
        let searchFilters = {
            severity
        }

        // TODO Add Severity to search 
        let query = this.session.getQuery({page, siteId, searchFilters})

        let config = {
            method: 'get',
            url: `/api/systemmessages/active${"?" + query}`,
        }

        let res = await this.axios(config)
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Get All System Messages
     * @returns {ResponseObject} Api response object 
     */
    async getAllSystemMessages({page = 0, severity = "All", isActive = null, sortBy = null, order = null, countOnly = false} = {}) {
        let searchFilters = {
            ...(sortBy !== null && {sortBy: sortBy}),
            ...(order !== null && {order: order}),
            severity,
            ...(isActive !== null && {isActive})
        }

        // TODO Add Severity to search 
        let query = this.session.getQuery({page, searchFilters, countOnly})

        let config = {
            method: 'get',
            url: `/api/systemmessages${"?" + query}`,
        }

        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get system message by id
     * @param {object} parameters properties to get by
     * @param {string} parameters.id System message id to get 
     * @returns {ResponseObject} Api response object
     */
    async getSystemMessage({id}) {
        let config = {
            method: 'get',
            url: `/api/systemmessages/${id}?populate=simple`,
        }

        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Creates a system message
     * @param {Object} params Function parameters
     * @param {string} params.name System message name/title
     * @param {string} params.description System message description
     * @param {string} params.severity System message severity
     * @param {string[]} params.sites Sites who can see system message
     * @param {string[]} params.userRoles User roles who can see the system message
     * @param {string} params.startAt Time to start showing system message
     * @param {string} params.endAt Time to stop showing the system message
     * @returns {ResponseObject} Api response object
     */
    async createSystemMessage({ name = null, description = null, severity = "", sites = [], userRoles = [], startAt = null, endAt = null } = {}) {
        if (startAt !== null) {
            startAt = new Date(startAt).toISOString()
        }
        
        if (endAt !== null) {
            endAt = new Date(endAt).toISOString()
        }
        
        let data = {
            ...((name) ? {Title: name} : {}),
            ...((description) ? {Description: description} : {}),
            ...((severity) ? {Severity: severity} : {}),
            ...((sites) ? {Sites: sites.map((site) => (site.guid))} : {}),
            ...((userRoles) ? {UserRoles: userRoles.map((userRole) => (userRole.guid))} : {}),
            ...((startAt) ? {StartAt: startAt} : {}),
            ...((endAt) ? {EndAt: endAt} : {}),
        }

        let config = {
            method: 'post',
            url: `/api/systemmessages`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }
          
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Delete a system message
     * @param {Object} params Function parameters
     * @param {string} params.id System message id
     * @returns {ResponseObject} Api response object
     */
    async deleteSystemMessage({id}) {
        let config = {
            method: "delete",
            url: `/api/systemmessages/${id}`,
            headers: {
                'Content-Type': 'application/json'
            },
        }
          
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Saves system message settings
     * @param {Object} params Function parameters
     * @param {string} params.name System message name/title
     * @param {string} params.description System message description
     * @param {string} params.severity System message severity
     * @param {string[]} params.sites Sites who can see system message
     * @param {string[]} params.userRoles User roles who can see the system message
     * @param {string} params.startAt Time to start showing system message
     * @param {string} params.endAt Time to stop showing the system message
     * @param {boolean} params.isActive System message is active
     * @returns {ResponseObject} Api response object
     */
    async saveSystemMessage({ id = "", name = null, description = null, severity = "", sites = [], userRoles = [], startAt = null, endAt = null, isActive = null} = {}) {
        if (startAt !== null) {
            startAt = new Date(startAt).toISOString()
        }

        if (endAt !== null) {
            endAt = new Date(endAt).toISOString()
        }
        
        let data = {
            ...((name !== null) && {Title: name}),
            ...((description !== null) && {Description: description}),
            ...((severity !== null) && {Severity: severity}),
            ...((sites !== null) && {Sites: sites.map((site) => (site.guid))}),
            ...((userRoles !== null) && {UserRoles: userRoles.map((userRole) => (userRole.guid))}),
            ...((startAt !== null) && {StartAt: startAt}),
            ...((endAt !== null) && {EndAt: endAt}),
            ...((isActive !== null) && {IsActive: (isActive === "true" || isActive === true)})
        }
        
        let config = {
            method: 'put',
            url: `/api/systemmessages/${id}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }
          
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
}

export default SystemMessages