/**
 * Class for handling subsites REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file subsites.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/
class Subsites {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * @description Gets subsites
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {boolean} searchfilters.isActive Filter by is active
     * @returns {ResponseObject} Api response object
     */
    async getSubSites(siteId = null, {page = 0, order = "ASC", search = "", sortBy = "", isActive = null} = {}) {
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            ...(isActive !== null && {isActive: isActive}),
        }
        
        let query = this.session.getQuery({page, siteId, searchFilters})

        let config = {
            method: 'get',
            url: `/api/sites${"?" + query}`,
        }

        let res = await this.axios(config)

        return (
            {
                status: res.status,
                statusText: res.statusText,
                isSuccess: (res.status >= 200 && res.status < 300),
                data: res.data
            }
        )
    }

    /**
     * @description Gets subsites
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {boolean} searchfilters.isActive Filter by is active
     * @param {string} searchFilters.countOnly Get count only, default true
     * @param {string} searchfilters.siteId Site id to filter subsites by
     * @param {boolean} searchFilters.isRootSite Filter by root site
     * @returns {ResponseObject} Api response object
     */
    async getAllSubSites({page = 0, order = "ASC", search = "", sortBy = "", isActive = null, siteId = null, countOnly = false, isRootSite = null} = {}) {
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            ...(isActive !== null && {isActive: isActive}),
            ...(siteId !== null && {siteId: siteId}),
            countOnly,
            ...(isRootSite !== null && {isRootSite: isRootSite}),
        }
        
        let query = this.session.getQuery({page, searchFilters})

        let config = {
            method: 'get',
            url: `/api/sites/all${"?" + query}`,
        }

        let res = await this.axios(config)

        return (
            {
                status: res.status,
                statusText: res.statusText,
                isSuccess: (res.status >= 200 && res.status < 300),
                data: res.data
            }
        )
    }

    /**
     * @description Get subsite count
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {boolean} searchfilters.isActive Filter by is active
     * @param {string} searchFilters.countOnly Get count only, default true
     * @returns {ResponseObject} Api response object
     */
    async getCount(siteId = null, {page = 0, order = "ASC", search = "", sortBy = "", countOnly = true} = {}) {
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            countOnly
        }

        let query = this.session.getQuery({page, siteId, searchFilters})
        let config = {
            method: 'get',
            url: `/api/sites${"?" + query}`,
        }

        let res = await this.axios(config)

        return (
            {
                status: res.status,
                statusText: res.statusText,
                isSuccess: (res.status >= 200 && res.status < 300),
                data: res.data
            }
        )
    }

    /**
     * @description Get a single subsite's information by id
     * @param {object} params Call parameters
     * @param {string} params.id Subsite uuid  
     * @returns {ResponseObject} Api response object
     */
    async getSubsite({id}) {
        let config = {
            method: 'get',
            url: `/api/sites/${id}?populate=simple`,
        }
          
        let res = await this.axios(config)
        
        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Deletes a specified subsite by id
     * @param {Object} params Function parameters
     * @param {string} params.id Subsite id
     * @returns {ResponseObject} Api response object
     */
    async deleteSubsite({id}) {
        let config = {
            method: 'delete',
            url: `/api/sites/${id}`,
            headers: { 
                'Content-Type': 'application/json'
            },
        }
          
        let res = await this.axios(config)
        
        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Creates a subsite
     * @param {object} params Function parameters
     * @param {string} params.siteId Site id of subsite
     * @param {string} params.name Subsite name
     * @param {string} params.address Subsite street address
     * @param {string} params.city Subsite city
     * @param {string} params.state Subsite state
     * @param {string} params.country Subsite country
     * @param {string} params.zip Subsite zipcode
     * @param {string} params.timezone Subsite timezone
     * @returns {ResponseObject} Api response object
     */
    async createSubsite({siteId = null, name = null, address = null, city = null, state = null, country = null, zip = null, timezone = null}) {
        if (siteId === null) {
            siteId = this.session.getSiteId()
        }

        let data = {
            "Name": name,
            "ParentSite": siteId,
            "Address": address,
            "State": state,
            "Zip": zip,
            "City": city,
            "Country": country,
            "TimeZone": timezone,
            "IsActive": true,
            "SubscriptionType": "Monthly",
            "OrganizationType": "Office"
        }

        let config = {
            method: 'post',
            url: '/api/sites',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }
          
        let res = await this.axios(config)
        
        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Save subsite settings by id
     * @param {Object} params Function parameters
     * @param {string} params.id Subsite id
     * @param {string} params.name Subsite name
     * @param {string} params.address Subsite street address
     * @param {string} params.city Subsite city
     * @param {string} params.state Subsite state
     * @param {string} params.country Subsite country
     * @param {string} params.zip Subsite zipcode
     * @param {string} params.timezone Subsite timezone
     * @param {boolean} params.isActive Subsite is active
     * @returns {ResponseObject} Api response object
     */
    async saveSubsite({id = null, name = null, address = null, city = null, state = null, country = null, zip = null, timezone = null, isActive = null}) {
        let data = {
            "Name": name,
            "Address": address,
            "State": state,
            "Zip": zip,
            "City": city,
            "Country": country,
            "TimeZone": timezone,
            "IsActive": (isActive === "true" || isActive === true),
        }

        let config = {
            method: 'put',
            url: `/api/sites/${id}`,
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }
          
        let res = await this.axios(config)
        
        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
}

export default Subsites