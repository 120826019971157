/**
 * @description Logs an error message in dev only
 * @param {string} str Error log message 
 */
function devErrorLog(str) {
    // eslint-disable-next-line no-undef
    if (process.env.DevMode) {
        console.error(str)
    }
}

/**
 * @description Logs an error message in dev only
 * @param {string} str Error log message
 * @param {string} str2 Optional params
 */
function devLog(str, str2 = null) {
    // eslint-disable-next-line no-undef
    if (process.env.DevMode) {
        if (str2 === null) {
            console.log(str)
        }
        else {
            console.log(str, str2)
        }
    }
}

/**
 * @description Logs a warning message in dev only
 * @param {string} str Error log message
 * @param {string} str2 Optional params
 */
function devWarnLog(str, str2 = null) {
    // eslint-disable-next-line no-undef
    if (process.env.DevMode) {
        if (str2 === null) {
            console.warn(str)
        }
        else {
            console.warn(str, str2)
        }
    }
}

export { devErrorLog, devWarnLog, devLog }