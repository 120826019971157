/**
 * Class for handling websocket Alerts 
 * @copyright 2021-2023 Soter Technologies, LLC. All rights reserved.
 * @file alerts.js
 * @author Matt Schreider, Paul Scala, Kyle Watkins
 */

import EventListener from "../../utilities/eventUtility"

/**
 * Class for Websocket Alerts
 */
class Alerts {
    /**
     * Initialize class
     * @param {object} session User session handler
     */
    constructor(clientHolder) {
        this.clientHolder = clientHolder
        this.receivedMessage = new EventListener()
    }

    /**
     * Join alerts room
     * @param {object} params join parameters
     * @param {string} params.siteId site id of room to join
     * @param {string} params.type notification type of room
     */
    joinRoom({siteId = '', type = 'alerts'} = {}) {
        this.clientHolder.ioClient.emit('join', {
            Action: 'join',
            SiteId: siteId,
            EventType: type
        })
    }

    /**
     * leave alerts room
     * @param {object} params leave parameters
     * @param {string} params.siteId site id of room to leave
     * @param {string} params.type notification type of room
     */
    leaveRoom({siteId = '', type = 'alerts'} = {}) {
        this.clientHolder.ioClient.emit('leave', {
            Action: 'leave',
            SiteId: siteId,
            EventType: type,
        })        
    }

    /**
     * listen to alert room
     * @param {object} params listen parameters
     * @param {string} params.action socket action
     * @param {string} params.eventType type to listen to
     * @param {boolean} params.status notification status 
     */
    listen() {
        this.clientHolder.ioClient.on("alerts", (msg) => {                                                
            // trigger socketState event listener
            this.receivedMessage.dispatchListeners(msg)
        })
    }
}

export default Alerts