/**
 * Class for handling forecast REST request
 * @copyright 2021-2024 Soter Technologies, LLC. All rights reserved.
 * @file forecast.js
 * @author Kyle Watkins, Paul Scala
*/

class Forecast {
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }  

    /**
     * Get forecast for site
     * @param {string} siteId Site id to get forecast for
     * @param {object} filters Forecast filters 
     * @returns {ResponseObject}
     */
    async getForecast(siteId = null, {alertType = [], deviceId, groupId, startTime, endTime, timeZone = 'utc'} = {}) {
        // If site id null
        if (siteId === null) {
            // Get current session site id
            siteId = this.session.getSiteId()
        }
        
        // Build search filters out
        let searchFilters = {
            alertType,
            startTime,
            endTime,
            ...(deviceId && {deviceId}),
            ...(groupId && {groupId}),
            timeZone
        }

        // Get query from site id and search filters
        let query = this.session.getQuery({siteId, searchFilters})

        // Build out config for request
        let config = {
            url: `/api/forecast/weekly?populate=simple&${query}`,
            method: 'get'
        }

        // Get forecast data
        let res = await this.axios(config) 
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
}

export default Forecast