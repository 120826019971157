/**
 * Class for handling webhook variables REST request
 * @copyright 2021-2023 Soter Technologies, LLC. All rights reserved.
 * @file webhookVariables.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/

class WebhookVariables {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * Get webhook variables
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {boolean} searchFilters.countOnly If true only get the count
     * @returns {ResponseObject} Api response object
     */
    async getWebhookVariables({page = 0, order = 'ASC', search = "", sortBy = "", countOnly = false} = {}) {
        // Initialize search filters
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
        }

        // Get query to filter by
        let query = this.session.getQuery({page, searchFilters, countOnly})

        // Set request config
        let config = {
            method: 'get',
            url: `/api/webhookvariables${"?" + query}`,
        }

        // Get webhook variables
        let res = await this.axios(config)
    
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
}

export default WebhookVariables