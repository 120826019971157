/**
 * Page Title handler
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file PageTitle.js
 * @author Kyle Watkins, Matt Schreider, Paul Scala
 */

import {useEffect} from 'react'
import { routeToName } from '../utilities/utilityFunctions'

/**
 * Handles changing the page title for each page
 * @param {object} props Function properties
 * @param {string} props.title Page title 
 * @returns Children
 */
const PageTitle = (props) => {
    // Use effect when page title changes
    useEffect(() => {
        // If page title is available
        if (props.title) {
            // Set document title
            document.title = `${process.env.SiteTitle} | ${routeToName(props.title).join(" | ").toUpperCase() || ""}`
        }
        else {
            // Set document title
            document.title = process.env.SiteTitle
        }
    }, [props.title])

    // If no children available return null
    if (props.children === undefined) {
        return null
    }

    // Return children
    return props.children
}

export default PageTitle