/**
 * Class for handling device REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file devices.js
 * @author Kyle Watkins, Paul Scala, Matt Schreider
 */

class Devices {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }
    
    /**
     * Get filtered devices
     * @param {string} siteId Site id to get devices for 
     * @param {object} query Query to filter
     * @param {number} query.page Page number of data
     * @param {string} query.order Order of data
     * @param {string} query.search String to search 
     * @param {string} query.sortBy Field to sort by
     * @param {string} query.model Device models to get
     * @param {boolean} query.countOnly Only get the count of devices back
     * @param {boolean} query.isOnline If true filter online devices, if false filter offline devices
     * @param {boolean} query.isActive If true filter active devices, if false filter inactive devices
     * @param {boolean} query.hasError If true filter devices with errors
     * @param {string[]} query.groupIds Group ids to filter by
     * @returns {ResponseObject} Api response object
     */
    async getDevices(siteId, {page = 0, order = 'ASC', search = "", sortBy = "", model = '', countOnly = false, isOnline = null, isActive = null, hasError = null, groupIds = []} = {}) {
        /**
         * Pseudo Code
         *  Initialize search filters
         *  Get query to filter by
         *  Set request config
         *  Get devices
         */

        // Initialize search filters
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            ...(model !== '' && {model: model}),
            ...(isOnline !== null && {isOnline: isOnline}), 
            ...(isActive !== null && {isActive: isActive}),
            ...(hasError !== null && {hasError: hasError}),
            groupId: groupIds
        }

        // Get query to filter by
        let query = this.session.getQuery({page, siteId, searchFilters, countOnly})

        // Set request config
        let config = {
            method: 'get',
            url: `/api/devices${"?" + query}`,
        }

        // Get devices
        let res = await this.axios(config)
    
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get number of online devices
     * @param {string} siteId Site id to get online devices for
     * @returns {ResponseObject} Api response object
     */
    async getOnlineDevices(siteId) {
        /**
         * Pseudo Code
         *  Initialize search filters
         *  Get query to filter by
         *  Set request config
         *  Get devices
         */
        
        // Get query to filter by
        let query = this.session.getQuery({siteId})

        // Set request config
        let config = {
            method: 'get',
            url: `/api/devices/online${"?" + query}`,
        }

        // Get devices
        let res = await this.axios(config)
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get all filtered devices
     * @param {object} query Query to filter
     * @param {number} query.page Page number of data
     * @param {string} query.order Order of data
     * @param {string} query.search String to search 
     * @param {string} query.sortBy Field to sort by
     * @param {string} query.model Model to filter by
     * @param {boolean} query.countOnly Only get the count of devices back
     * @param {string} query.siteId Site id to filter devices by
     * @param {boolean} query.isOnline If true filter online devices, if false filter offline devices
     * @param {boolean} query.isActive If true filter active devices, if false filter inactive devices
     * @param {boolean} query.hasError If true filter devices with errors
     * @returns {ResponseObject} Api response object
     */
    async getAllDevices({page = 0, order = 'ASC', search = "", sortBy = "", model = '', countOnly = false, siteId = null, isOnline = null, isActive = null, hasError = null} = {}) {
        /**
         * Pseudo Code
         *  Initialize search filters
         *  Get query to filter by
         *  Set request config
         *  Get devices
         */
        
        // Initialize search filters
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            ...(countOnly === true && {countOnly}),
            ...(siteId !== null && {siteId: siteId}),
            ...(model !== '' && {model: model}),
            ...(isOnline !== null && {isOnline: isOnline}), 
            ...(isActive !== null && {isActive: isActive}),
            ...(hasError !== null && {hasError: hasError})
        }
        
        // Get query to filter by
        let query = this.session.getQuery({page, searchFilters})

        // Set request config
        let config = {
            method: 'get',
            url: `/api/devices/all${"?" + query}`,
        }

        // Get devices
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get a device by id
     * @param {string} id Id of device to get
     * @param {object} options Get device options
     * @param {string} options.details If true get device configuration, default to true
     * @param {string} options.interfaceTypes Interface types to get
     * @param {boolean} options.populate If true get device configuration, default to true
     * @returns {ResponseObject} Api response object
     */
    async getDevice(id, {details = [], interfaceTypes = [], populate = true} = {}) {
        let params = []
        
        if (populate === true) {
            params.push('populate=simple')
        }
        
        if (details.length > 0) {
            details = 'details=' + details.join('&details=')
            params.push(details)
        }

        if (interfaceTypes.length > 0) {
            interfaceTypes = 'InterfaceTypes=' + interfaceTypes.join('&InterfaceTypes=')
            params.push(interfaceTypes)
        }

        // Set request config
        let config = {
            method: 'get',
            url: `/api/devices/${id}${(params.length !== 0 ? '?' + params.join('&') : '')}`,
        }
        
        // Get device
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    } 

    /**
     * Get filtered device names
     * @param {string} siteId Site id to get devices for 
     * @param {object} query Query to filter
     * @param {number} query.page Page number of data
     * @param {string} query.order Order of data
     * @param {string} query.search String to search 
     * @param {string} query.sortBy Field to sort by
     * @param {boolean} query.countOnly Only get the count of devices back
     * @returns {ResponseObject} Api response object
     */
    async lookupDevices(siteId, {page = 0, order = 'ASC', search = "", sortBy = "", countOnly = false} = {}) {
        /**
         * Pseudo Code
         *  Initialize search filters
         *  Get query to filter by
         *  Set request config
         *  Get devices
         */

        // Initialize search filters
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
        }

        // Get query to filter by
        let query = this.session.getQuery({page, siteId, searchFilters, countOnly})

        // Set request config
        let config = {
            method: 'get',
            url: `/api/devices/lookup${"?" + query}`,
        }

        // Get devices
        let res = await this.axios(config)
    
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get a device's name by id
     * @param {string} id Id of device to get name for
     * @returns {ResponseObject} Api response object
     */
    async getDeviceName(id) {
        // Set request config
        let config = {
            method: 'get',
            url: `/api/devices/${id}/name`,
        }
        
        // Get device
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    } 
    
    /**
     * Get a device's status by id
     * @param {string} id Id of device to get
     * @returns {ResponseObject} Api response object
     */
    async getDeviceStatus(id) {
        // Set request config
        let config = {
            method: 'get',
            url: `/api/devices/${id}/status`,
        }
        
        // Get device
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    } 
    
    /**
     * Get a devices sensor graph
     * @param {string} id Id of device to get sensor graphs for
     * @returns {ResponseObject} Api response object
     */
    async getDeviceSensorGraphs(id) {
        // Set request config
        let config = {
            method: 'get',
            url: `/api/devices/${id}/sensorgraphs`,
        }
        // Get device
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get a device by id
     * @param {string} id Id of device to get
     * @param {object} searchFilters searchFilters object
     * @param {number} searchFilters.priorityLimit priority limit value
     * @returns {ResponseObject} Api response object
     */
    async getDeviceStreamingTypes(id, {priorityLimit = null}) {
        // Set request config
        let config = {
            method: 'get',
            url: `/api/devices/${id}/streamingtypes/categorized?${(priorityLimit !== null ? `priorityLimit=${priorityLimit}` : '')}`
        }
        
        // Get device
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    } 

    /**
     * End outstanding device alerts
     * @param {string} id Device id to end alerts for
     * @returns {ResponseObject} Api response object
     */
    async endDeviceAlerts(id) {
        // Set request config
        let config = {
            method: 'put',
            url: `/api/devices/${id}/endalerts`,
        }
        
        // Get device
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Delete a device
     * @param {object} properties Device properties
     * @param {string} properties.id Device id to delete
     * @returns {ResponseObject} Api response object
     */
    async deleteDevice({id}) {
        // Set request config
        let config = {
            method: 'delete',
            url: `/api/devices/${id}`,
            headers: { 
                'Content-Type': 'application/json'
            },
        }
        
        // Get devices
        let res = await this.axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText, 
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })   
    }

    /**
     * Creates a device
     * @param {object} properties device properties
     * @param {string} properties.siteId Site id of the device
     * @param {string} properties.name Device name
     * @param {string} properties.mac Device mac
     * @returns {ResponseObject} Api response object
     */
    async createDevice({siteId = null, name = '', mac = ''}) {
        /**
         * Pseudo Code
         *  Get site id
         *  Set request config
         *  Make request
         */
        
        // Get site id
        if (siteId === null) {
            siteId = this.session.getSiteId()
        }

        // Set request config
        let config = {
            method: 'post',
            url: `/api/devices`,
            headers: { 
                'Content-Type': 'application/json'
            },
            data: {
                Name: name,
                Mac: mac.toUpperCase(),
                SiteId: siteId
            }
        }
        
        // Get devices
        let res = await this.axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText, 
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })    
    }

    /**
     * Save device settings
     * @param {object} properties
     * @param {string} properties.id Id of device to save
     * @param {string} properties.name New name of device
     * @param {boolean} properties.isActive Activre devices to get 
     * @param {object} properties.settings Device settings to save
     * @returns {ResponseObject} Api response object
     */
    async saveDevice({id = null, name = null, isActive = null, settings = null}) {
        // Set request config
        let config = {
            method: 'put',
            url: `/api/devices/${id}`,
            headers: { 
                'Content-Type': 'application/json'
            },
            data: {
                ...(name !== null && {Name: name}),
                ...(isActive !== null && {IsActive: isActive}),
                ...(settings !== null && {Settings: settings})
            }
        }
        
        // Get devices
        let res = await this.axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText, 
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })   
    }

    /**
     * Refresh a reset device
     * @param {string} deviceId Devie id to refresh 
     * @returns {ResponseObject} Api response object
     */
    async refreshDevice(deviceId) {
        // Set request config
        let config = {
            method: 'post',
            url: `/api/devices/${deviceId}/refresh`,
            headers: { 
                'Content-Type': 'application/json'
            },
        }
        
        // Get devices
        let res = await this.axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText, 
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })   
    }

    /**
     * @description Get alert types for a device
     * @param {string} deviceId Device id to get alert types for
     * @returns {ResponseObject} Api response object
     */
    async getAlertTypes(deviceId, options = '') {
        let config = {
            method: 'get',
            url: `/api/devices/${deviceId}/alerttypes`,
        }

        let res = await this.axios(config)
        
        return (
            { 
                status: res.status,
                statusText: res.statusText,
                isSuccess: (res.status >= 200 && res.status < 300),
                data: res.data
            }
        )
    }
}

export default Devices