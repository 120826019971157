/**
 * Class for handling webhook events REST request
 * @copyright 2021-2023 Soter Technologies, LLC. All rights reserved.
 * @file webhookEvents.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/

class WebhookEvents {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * Get webhook events
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {boolean} searchFilters.isActive Filter by isActive
     * @param {string[]} searchFilters.deviceIds Device ids to filter by
     * @param {string[]} searchFilters.webhookRequest Webhook request id to filter by
     * @param {string[]} searchFilters.alertType Alert types to filter by, defaults to ['All']
     * @param {string[]} searchFilters.groupIds Group Ids to filter by
     * @param {'none'|'simple'} query.populate  If set to simple replace all ids to show name and id, default to none which means no ids will be populated in the return
     * @returns {ResponseObject} Api response object
     */
    async getWebhookEvents(siteId, {page = 0, order = "ASC", search = "", sortBy = "", isActive = null, deviceIds = [], webhookConfigId = [], alertType = ['All'], groupIds = [], populate = 'none'} = {}) {
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            ...(isActive !== null && {isActive: isActive}),
            deviceId: deviceIds,
            alertType: alertType,
            groupId: groupIds,
            webhookConfigId: webhookConfigId
        }

        let query = this.session.getQuery({page, siteId, searchFilters})
        
        let config = {
            method: 'get',
            url: `/api/webhookevents${`?populate=${populate}` + (query ? `&${query}` : '')}`,
        }
          
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get webhook events by day
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {boolean} searchFilters.isActive Filter by isActive
     * @param {string[]} searchFilters.dayOfWeek Filter by day of week, defaults to all
     * @param {string[]} searchFilters.deviceIds Device ids to filter by
     * @param {string[]} searchFilters.alertType Alert types to filter by, defaults to ['All']
     * @param {string[]} searchFilters.groupIds Group Ids to filter by
     * @returns {ResponseObject} Api response object
     */
    async getWebhookEventsByDay(siteId = null, {page = 0, order = "ASC", search = "", sortBy = "", isActive = null, daysOfWeek = ['All'], deviceIds = [], alertType = ['All'], groupIds = []} = {}) {
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            ...(isActive !== null && {isActive: isActive}),
            daysOfWeek: daysOfWeek,
            deviceId: deviceIds,
            alertType: alertType,
            groupId: groupIds,
        }
        
        let query = this.session.getQuery({page, siteId, searchFilters})
        
        let config = {
            method: 'get',
            url: `/api/webhookevents/by/day${"?" + query}`,
        }
        
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Get webhook request count
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @returns {ResponseObject} Api response object
     */
    async getCount(siteId, {page = 0, order = "ASC", search = "", sortBy = ""} = {}) {
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search: encodeURIComponent(search)},
            ...(sortBy === "") ? {} : {sortBy},
            countOnly: true
        }

        let query = this.session.getQuery({page, siteId, searchFilters})
        
        let config = {
            method: 'get',
            url: `/api/webhookevents${"?" + query}`,
        }
          
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Get a single webhookEvent information by id
     * @param {string} id WebhookEvent uuid  
     * @returns {ResponseObject} Api response object
     */
    async getWebhookEvent({id}) {
        let config = {
            method: 'get',
            url: `/api/webhookevents/${id}?populate=simple`
        }

        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        }) 
    }

    /**
     * Create webhook event
     * @param {object} params Webhook event parameters
     * @param {string} params.siteId Site id of site associated with the webhook event
     * @param {string} params.name Webhook event name
     * @param {string} params.isActive Webhook event is active, default true
     * @param {string[]} params.sites Array of sites uuids in webhookEvent
     * @param {string[]} params.devices Array of device uuids in webhookEvent
     * @param {string[]} params.lists Array of lists uuids in webhookEvent
     * @param {string[]} params.users Array of users uuids in webhookEvent
     * @param {string[]} params.groups Array of groups uuids in webhookEvent
     * @param {string[]} params.events Array of events uuids in webhookEvent
     * @param {number} params.startTime Start time by hour
     * @param {number} params.endTime End time by hour
     * @param {boolean} params.sunday If true alerts will be sent on sunday
     * @param {boolean} params.monday If true alerts will be sent on monday
     * @param {boolean} params.tuesday If true alerts will be sent on tuesday
     * @param {boolean} params.wednesday If true alerts will be sent on wednesday
     * @param {boolean} params.thursday If true alerts will be sent on thursday
     * @param {boolean} params.friday If true alerts will be sent on friday
     * @param {boolean} params.saturday If true alerts will be sent on saturday
     * @param {string[]} params.alertTypes Alert types for webhookEvent to receive
     * @returns {ResponseObject} Api response object
     */
    async createWebhookEvent({siteId = null, name = "", isActive = true, sites = [], devices = [], webhookConfigId = null, params = {}, groups = [], events = [], startTime = 7, endTime = 19, sunday = false, monday = false, tuesday = false, wednesday = false, thursday = false, friday = false, saturday = false, alertTypes = []}) {
        if (siteId === null) {
            siteId = this.session.getSiteId()
        }

        let data = {
            IsActive: isActive,
            Name: name,
            Site: siteId,
            Devices: devices,
            WebhookRequest: webhookConfigId,
            Params: params,
            Sites: sites,
            Groups: groups,
            Events: events,
            StartTime: startTime,
            EndTime: endTime,
            Sunday: sunday,
            Monday: monday,
            Tuesday: tuesday,
            Wednesday: wednesday,
            Thursday: thursday,
            Friday: friday,
            Saturday: saturday,
            AlertTypes: alertTypes,
        } 
        
        let config = {
            method: 'post',
            url: '/api/webhookevents',
            data: data
        }

        let res = await this.axios(config)

        return({
            status: res.status,
            statusText: res.statusText, 
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        }) 
    }

    /**
     * Save webhook events
     * @param {object} params WebhookEvent parameters
     * @param {number} params.id WebhookEvent id
     * @param {string} params.name Webhook Event name
     * @param {string} params.siteId Site id of site associated with the webhook event
     * @param {string} params.isActive WebhookEvent is active
     * @param {string} params.type WebhookEvent type
     * @param {string[]} params.sites Array of sites uuids in webhookEvent
     * @param {string[]} params.devices Array of device uuids in webhookEvent
     * @param {string[]} params.groups Array of groups uuids in webhookEvent
     * @param {string[]} params.events Array of events uuids in webhookEvent
     * @param {number} params.startTime Start time by hour
     * @param {number} params.endTime End time by hour
     * @param {boolean} params.sunday If true alerts will be sent on sunday
     * @param {boolean} params.monday If true alerts will be sent on monday
     * @param {boolean} params.tuesday If true alerts will be sent on tuesday
     * @param {boolean} params.wednesday If true alerts will be sent on wednesday
     * @param {boolean} params.thursday If true alerts will be sent on thursday
     * @param {boolean} params.friday If true alerts will be sent on friday
     * @param {boolean} params.saturday If true alerts will be sent on saturday
     * @param {string[]} params.alertTypes Alert types for webhookEvent to receive
     * @returns {ResponseObject} Api response object
     */
    async saveWebhookEvent({id = null, name = "", siteId = null, webhookConfigId = null, params = {}, isActive = true, sites = [], devices = [], groups = [], events = [], startTime = 7, endTime = 19, sunday = false, monday = false, tuesday = false, wednesday = false, thursday = false, friday = false, saturday = false, alertTypes = []}) {
        let data = {
            IsActive: isActive,
            Name: name,
            WebhookRequest: webhookConfigId,
            Params: params,
            Devices: devices,
            Site: siteId,
            Sites: sites,
            Groups: groups,
            Events: events,
            StartTime: startTime,
            EndTime: endTime,
            Sunday: sunday,
            Monday: monday,
            Tuesday: tuesday,
            Wednesday: wednesday,
            Thursday: thursday,
            Friday: friday,
            Saturday: saturday,
            AlertTypes: alertTypes
        } 
        
        let config = {
            method: 'put',
            url: `/api/webhookevents/${id}`,
            data: data
        }

        let res = await this.axios(config)

        return({
            status: res.status,
            statusText: res.statusText, 
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        }) 
    }

    /**
     * @description Delete webhookevent by id
     * @param {string} id WebhookEvent uuid     
     * @returns {ResponseObject} Api response object
     */
    async deleteWebhookEvent({id}) {
        let trigger = {
            method: 'delete',
            url: `/api/webhookevents/${id}`
        }

        let res = await this.axios(trigger)

        return({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        }) 
    }
}

export default WebhookEvents