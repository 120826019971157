import React, {useState, useEffect} from "react"

/**
 * @template C
 * @description Wrap your export with this function to utilize window size
 * @param {C} WrappedComponent Component to wrap with Window ize
 * @returns {C} Wrapped Compenent with window size(dimensions) in props
 */
export function WindowSize(WrappedComponent) {
    /**
     * @type {C}
     */
    function Component(props) {
        // Inner height and width
        const [dimensions, setDimensions] = useState({
            width: window.innerWidth,
            height: window.innerHeight,
        })
        
        useEffect(() => {
            const debouncedResizeHandler = debounce(() => {
                setDimensions({width: window.innerWidth, height: window.innerHeight})
            }, 500) // 100ms
            window.addEventListener('resize', debouncedResizeHandler)
            return () => window.removeEventListener('resize', debouncedResizeHandler)
        }, [])
            
        let updatedProps = {dimensions}
        return <WrappedComponent {...props} {...updatedProps} />
    }
    
    // Give properties to outer components
    let WrappedComponentKeys = Object.keys(WrappedComponent)
    WrappedComponentKeys.forEach((key) => {
        if (key !== 'WrappedComponent' && key !== 'displayName') {
            Component[key] = WrappedComponent[key]
        }
    })

    return Component
}

/**
 * @description Handle debounce on resize
 * @param {function} fn Function to run on debounce 
 * @param {number} ms Time to reset debounce
 * @returns 
 */
function debounce(fn, ms) {
    let timer
    return () => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    }
}