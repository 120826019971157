/**
 * Login Page Component
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file login.js
 * @author Kyle Watkins, Matt Schreider, Paul Scala
 */

import React from 'react'
import GlassHolder from "../stateless/GlassHolder"
import GlassBox from "../stateless/GlassBox"
import GlassHeader from "../stateless/GlassHeader"
import { withRouter } from 'react-router-dom'
import LoginForm from '../components/LoginForm'

function Login(props) {
    return (
        <GlassHolder height="500px" maxWidth="500px">
            <GlassBox>
                <title>
                    {`${process.env.SiteTitle} | LOG IN TO EOTLIVE`}
                </title>
                <GlassHeader title="LOG IN" />
                <LoginForm setCreds={props.setCreds} history={props.history} />
            </GlassBox>
        </GlassHolder>
    )
    
}

export default withRouter(Login)
