/**
 * Loading Page Component
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file loading.js
 * @author Kyle Watkins, Matt Schreider, Paul Scala
 */

import React, { useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import { Loader, Transition } from 'semantic-ui-react'
import ApiManager from '../api/ApiManager'
import { devLog } from '../utilities/loggingUtility'
import Themes from '../utilities/themes'
import { decodeSearchParams } from '../utilities/utilityFunctions'

/**
 * @description Loading page where aquiring user login info is handled
 * @param {Object} props Properties of Loading
 * @param {Object} credentials Credentials containing user input for email and password
 * @param {function} clearPassword Clear Password from state
 * @param {function} clearSession Clear Session from state
 * @param {function} loginUser Login User
 * @param {function} failAuth Fail User authentification 
 * @returns 
 */
function Loading(props) {
    // const [attempts, setAttempts] = useState(0)
    const {history} = props
    useEffect(() => {
        const onSessionCreated = () => {
            /**
             * Pseudo Code
             *  If login succeeds
             *      Load theme
             *      Authorize user
             *      Go to dashboard
             *      Apply theme
             *  Else login fails
             *      If login times out
             *          devLog timeout
             *      Else if no access token available. (No login attempted)
             *          Go to login keep location state
             *      Else
             *          Unauthorize user
             *          Create error search params
             *          Dev log reason
             *          Go to login page with error message
             */

            // Get login error
            let response = ApiManager.session.getLoginError()

            // If login succeeds
            if (response === undefined) {
                // Load Theme
                let theme = ApiManager.session.userSettings.Theme ?? process.env.DefaultTheme
                
                // If theme is not available get default theme
                if (!(theme in Themes)) {
                    theme = process.env.DefaultTheme
                }

                if (theme === 'Default') {
                    theme = ApiManager.session.siteSettings.Theme
                }
                else if (theme === 'Auto') {
                    theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'Dark' : 'Light'
                }

                // Get last page on before loading
                let from

                if (history.location?.state?.from?.pathname !== '/timeout') {
                    from = history.location?.state?.from
                }

                // If from url path has search param
                if (from?.search !== undefined) {
                    // set current site id from url search param
                    ApiManager.session.setCurrentSiteId(decodeSearchParams(from.search).siteId)
                }
                
                // Go to last page or dashboard
                history.push(from ?? '/dashboard')
                
                // Apply theme
                document.body.className = theme
            }
            // If login fails
            else {
                // No access token (No login attempted).
                if (response.invalid === 'accessToken') {
                    // Push error to login page
                    history.push({pathname: "/login", state: history?.location?.state})
                }
                // When user login is invalid
                else {
                    // Init search
                    let search 
                    if (!(response.reason === 'invalid_permissions' && history.location.state?.from !== undefined)) { 
                        search = `invalid=${response.invalid}`
                    }

                    // Log reason for failed login if in dev mode
                    devLog(response.reason)
                    
                    // Push error to login page
                    history.push({pathname: "/login", search: search, state: history.location.state})
                }
            }
        }

        const onSessionDestroyed = () => {
            /**
             * Pseudo code
             *  Unauthorize user
             *  Go to login
             */

            document.body.className = ''
            history.push({pathname: '/login'})
        }

        // Add event listeners
        ApiManager.session.addCreateSessionListener(onSessionCreated)
        ApiManager.session.addDestroySessionListener(onSessionDestroyed)

        return () => {
            // Remove event listeners
            ApiManager.session.removeCreateSessionListener(onSessionCreated)

            if (ApiManager.session.Authorized === false) {
                ApiManager.session.removeDestroySessionListener(onSessionDestroyed)
            }
        }
    }, [])

    useEffect(() => {
        ApiManager.session.createSession()
 
        /*

            if (res.data?.ErrorDetail === 'Temporary password expired') {
                path = '/login'
                search = `invalid=passwordExpired`
            }
            else if (res.data?.ErrorDetail === 'Session is expired') {
                path = '/login'
                search = `invalid=sessionExpired`
            }
            else if (res.data?.ErrorDetail === 'Password is invalid') {
                path = '/update-password'
                search = `invalid=invalidPassword&session=${credentials.session}&email=${credentials.email}`
            }
            else if (res.data?.ErrorDetail === "Invalid credentials") {
                path = '/login'
                search = `invalid=login`
                reason = "invalid_credentials"
            }
            else if (res.data?.ErrorDetail === "User not confirmed") {
                path = '/login'
                search = `invalid=confirmed`
                reason = "account_not_confirmed"
            }
            else if (res.data?.ErrorDetail === "Limit exceeded") {
                path = '/login'
                search = `invalid=limit`
                reason = "limit_exceeded"
            }
            else if (res.data?.ErrorDetail === "New password required") {
                if (res.data?.SessionToken) {
                    path = '/update-password'
                    search = `session=${res.data.SessionToken}&email=${credentials.email}`
                }
            }
            else if (res.status === 504) {
                return
            }
        */
    }, [])
    
    return (
        <div className="imageHolder">
            <Transition animation='fade' duration={1000} transitionOnMount>
                <div className="full glass">
                    <Loader active size='massive' />
                </div>
            </Transition>
        </div>
    )
}

export default withRouter(Loading)
