import React from "react"
import ReactDom from "react-dom"
import App from "./app"
import '@fortawesome/fontawesome-pro/css/all.css'
import '@fortawesome/fontawesome-pro/css/sharp-solid.css'
import "../semantic-ui/semantic.less"
import "./resources/css/main.css"
import "./resources/css/main-headerbar.css"
import "./resources/less/main.less"
import "./resources/less/mobile.less"
import "./resources/less/print.less"
import "./resources/less/mozilla.less"

ReactDom.render(<App />, document.getElementById('app'), () => {
    // Apply default theme
    document.body.className = process.env.DefaultTheme
})
