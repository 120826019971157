/**
 * Api Utility Functions
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file ApiUtilities.js
 * @author Kyle Watkins, Paul Scala, Matt Schreider
 */

/**
 * @description Takes in name, value, and days to expiration and stores in local storage
 * @param {string} name Name of local storage item
 * @param {string} value Value of local storage item
 * @param {number} days Days to expire
 */
function addToLocalStorage(name, value, days) {
    /**
     * Pseudo Code
     *  Get current date
     *  Set time expiration date to any amount of days
     *  Set item in local storage
     */
    let date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    let item = {
        value: value,
        expiry: date.getTime()
    }

    window.localStorage.setItem(name, JSON.stringify(item))
}

/**
 * @description Takes in the name of a local storage item and returns the value
 * @returns Value of specified cookie
 */
function readFromLocalStorage(name) {
    /**
     * Pseudo Code
     *  Get item from local storage by name
     *      If item doesn't exist return null
     *  Parse item string
     *  Get date
     *  If time has reach expiration
     *      Erase item and return null
     *  Return item value
     */

    const itemStr = localStorage.getItem(name)
    
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()
    
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        eraseFromLocalStorage(name)
        return null
    }

    return item.value
}

/**
 * @description Erases an item from local storage
 * @param {string} name Name of item to delete from local storage
 */
function eraseFromLocalStorage(name) {
    /**
     * Pseudo Code
     *  Remoce item from local storage
     */

    localStorage.removeItem(name)
}

/**
 * Clear all items from loacl storage
 */
function clearLocalStorage() {
    /**
     * Pseudo Code
     *  Map through each key in local storage and erase them
     */

    let keys = Object.keys(window.localStorage)
    keys.forEach((key) => {
        eraseFromLocalStorage(key)
    })
}

/**
 * Capitalize first letter of string
 * @param {string} val String to capitalize first letter of 
 * @returns {string} String with the first letter capitialized
 */
function capitalizeFirstLetter(val) {
    /**
     * Pseudo Code
     *  If is an array
     *      Capitalize each first letter of each string in array
     *  Else
     *      Capitilize the first letter of the string
     */

    if (Array.isArray(val)) {
        for(let i = 0 ; i < val.length ; i++) {
            val[i] = val[i].charAt(0).toUpperCase() + val[i].slice(1)
        } 
    }
    else {
        val = val.charAt(0).toUpperCase() + val.slice(1)
    }

    return val
}

/**
 * @description Check if a value is a boolean
 * @param {any} val Any value to check if its an boolean 
 * @returns {boolean} true if value is a boolean, false if it isn't
 */
function isBoolean(val) {
    return val === false || val === true
}

/**
 * @description Check if a value is an object
 * @param {any} objValue Any value to check if its an object 
 * @returns {boolean} true if objValue is an object, false if it isn't
 */
function isObject(objValue) {
    return objValue && typeof objValue === 'object' && objValue.constructor === Object
}

export {addToLocalStorage, readFromLocalStorage, eraseFromLocalStorage, clearLocalStorage, capitalizeFirstLetter, isBoolean, isObject}