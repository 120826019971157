import React from "react"
import { Segment } from 'semantic-ui-react'

/**
 * Glass Box component
 * @param {Object} props 
 * @param {Object} props.children Glass Box content
 * @param {boolean} props.allowOverflow true to set overflow 'hidden', false set 'unset'
 * @param {number} props.overflowHeight amount of overflow in px caused by qr reader
 * @returns Component
 */
function GlassBox(props) {
    return (
        <Segment className="glassSegment" style={{overflow: props.allowOverflow ? 'hidden' : 'unset', marginBottom: props.overflowHeight !== 0 && window.innerHeight < 700 ? props.overflowHeight / 2 : 0}}>
            { props.children }
        </Segment>
    )
}

export default GlassBox