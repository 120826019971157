/**
 * Class for handling models REST request
 * @copyright 2021-2023 Soter Technologies, LLC. All rights reserved.
 * @file models.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/

class Models {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    async getAllModels() {
        let config = {
            method: 'get',
            url: `/api/models/all`,
        }

        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
}

export default Models