/**
 * Generic class for Event Listeners 
 * @copyright 2021-2023 Soter Technologies, LLC. All rights reserved.
 * @file eventUtility.js
 * @author Matt Schreider, Paul Scala, Kyle Watkins
 */

/**
 * Event Listener utility class
 */
class EventListener {
    constructor() {
        this.listeners = []
    }

    /**
     * Add function to create websocket listener
     * @param {function} func Function to add to listener 
     */
    addListener(func) {
        /**
         * Pseudo Code
         *  Add function to event listener
         */
        this.listeners.push(func)
    }

    /**
     * Remove function listener
     * @param {function} func Function to remove from listener 
     */
    removeListener(func) {
        /**
         * Pseudo Code
         *  Get index of function
         *  If index is not -1
         *      Remove function from event listener
         */
        let indexToRemove = this.listeners.indexOf(func)
        
        if (indexToRemove > -1) {
            this.listeners.splice(indexToRemove, 1)
        }
    }

    /**
     * Dispatch event listeners
     * @param {}
     */
    async dispatchListeners(...args) {
        /**
         * Pseudo Code
         *  For each function in event listener
         *      Get and run listener
         */
        for (let index = 0; index < this.listeners.length; index++) {
            let listener = this.listeners[index]
            await listener(...args)
        }
    }
}

export default EventListener