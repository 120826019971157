/**
 * Class for handling about REST request
 * @copyright 2021-2023 Soter Technologies, LLC. All rights reserved.
 * @file About.js
 * @author Kyle Watkins, Matt Schreider, Paul Scala
 */

class About {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor (axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * Get about
     * @returns {ResponseObject} Api response object
     */
    async getAbout() {
        let config = {
            method: 'get',
            url: `/api/about`,
        }

        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
}

export default About