/**
 * Class for handling group REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file groups.js
 * @author Kyle Watkins, Paul Scala, Matt Schreider
 */

class Groups {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * @description Gets groups
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {boolean} searchFilters.isActive Filter by isActive
     * @returns {ResponseObject} Api response object
     */
    async getGroups(siteId = null, {page = 0, order = "ASC", search = "", sortBy = "", isActive = null} = {}) {
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            ...(isActive !== null && {isActive: isActive})
        }

        let query = this.session.getQuery({page, siteId, searchFilters})

        let config = {
            method: 'get',
            url: `/api/groups${"?" + query}`,
        }

        let res = await this.axios(config) 
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get filtered group names
     * @param {string} siteId Site id to get groups for 
     * @param {object} query Query to filter
     * @param {number} query.page Page number of data
     * @param {string} query.order Order of data
     * @param {string} query.search String to search 
     * @param {string} query.sortBy Field to sort by
     * @param {boolean} query.countOnly Only get the count of groups back
     * @returns {ResponseObject} Api response object
     */
    async lookupGroups(siteId, {page = 0, order = 'ASC', search = "", sortBy = "", countOnly = false} = {}) {
        /**
         * Pseudo Code
         *  Initialize search filters
         *  Get query to filter by
         *  Set request config
         *  Get groups
         */

        // Initialize search filters
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
        }

        // Get query to filter by
        let query = this.session.getQuery({page, siteId, searchFilters, countOnly})

        // Set request config
        let config = {
            method: 'get',
            url: `/api/groups/lookup${"?" + query}`,
        }

        // Get groups
        let res = await this.axios(config)
    
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get a group's name by id
     * @param {string} id Id of group to get name for
     * @returns {ResponseObject} Api response object
     */
    async getGroupName(id) {
        // Set request config
        let config = {
            method: 'get',
            url: `/api/groups/${id}/name`,
        }
        
        // Get group
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    } 

    /**
     * @description Get group count
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {string} searchFilters.countOnly Get count only, default true
     * @returns {ResponseObject} Api response object
     */
    async getCount(siteId = null, {page = 0, order = "ASC", search = "", sortBy = "", countOnly = true} = {}) {
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            countOnly
        }

        let query = this.session.getQuery({page, siteId, searchFilters})
        let config = {
            method: 'get',
            url: `/api/groups${"?" + query}`,
        }

        let res = await this.axios(config) 
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Get a single group's information by id
     * @param {string} id Group uuid  
     * @returns {ResponseObject} Api response object
     */
    async getGroup({id}) {
        let config = {
            method: 'get',
            url: `/api/groups/${id}?populate=simple`,
        }

        let res = await this.axios(config) 
        
        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Deletes a specified group by id
     * @param {Object} params Function parameters
     * @param {string} params.id group id
     * @returns {ResponseObject} Api response object
     */
    async deleteGroup({id}) {
        let config = {
            method: 'delete',
            url: `/api/groups/${id}`,
        }
         
        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Creates a specified group
     * @param {Object} params Function parameters
     * @param {string} params.siteId Site id of group, defaults to null
     * @param {string} params.name Group name
     * @param {string[]} params.items Group of devices
     * @returns {ResponseObject} Api response object
     */
    async createGroup({siteId = null, name, items}) {
        if (siteId === null) {
            siteId = this.session.getSiteId()
        }
        
        let data = {
            Name: name,
            Site: siteId,
            Devices: items
        }
        
        let config = {
            method: 'post',
            url: '/api/groups',
            data: data
        }
         
        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Saves a specified group by id
     * @param {Object} params Function parameters
     * @param {string} params.name Group name
     * @param {boolean} params.isActive Group isActive
     * @param {string[]} params.items Group of devices
     * @returns {ResponseObject} Api response object
     */
    async saveGroup({id, name, items, isActive}) {
        let data = {
            Name: name,
            Devices: items,
            IsActive: isActive
        }
        
        let config = {
            method: 'put',
            url: `/api/groups/${id}`,
            data: data
        }
         
        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
}

export default Groups