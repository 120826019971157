/**
 * Socket State Helper Class
 * @copyright 2021-2023 Soter Technologies, LLC. All rights reserved.
 * @file socketState.js
 * @author Matt Schreider, Paul Scala, Kyle Watkins
 */

import EventListener from "../../../utilities/eventUtility"

/**
 * Singleton class for managing web socket state 
 */
class SocketState {
    constructor(websocket, rooms) {
        this.triggers = {}
        this.rooms = rooms
        this.alerts = websocket.alerts
        this.stateUpdated = new EventListener()
        this.handleWrappers = {}
    }

    /**
     * Add trigger
     * @param {string} type socket type
     */
    addTrigger({eventType = ""} = {}) {
        /**
         * Pseudo Code
         *  If trigger type doesnt exist 
         *      initialize trigger type object
         *      Add event listener to websocket type class
         */
        if (!this.triggers[eventType]) {
            this.triggers[eventType] = {}

            const wrapper = (...args) => this.handleMessage({eventType: eventType}, ...args)
            this.handleWrappers[eventType] = wrapper
            this[eventType].receivedMessage.addListener(wrapper)
        }
    }

    /**
     * Remove trigger
     * @param {string} type socket type
     */
    removeTrigger(eventType) {
        /**
         * Pseudo Code
         *  If trigger type exist
         *      delete trigger type
         *      delete received message event listener for trigger type
         */
        if (this.triggers[eventType]) {
            delete this.triggers[eventType]
            this[eventType].receivedMessage.removeListener(this.handleWrappers[eventType])
        }
    }
    
    /**
     * Handle socket message received
     * @param {string} socketType socket type
     */
    handleMessage({eventType = ""} = {}) {
        /**
         * Pseudo Code
         *  If socket type exist in triggers
         *      increment type count by 1 or start at 1
         *      dispatch state updated listeners
         */
        if (eventType in this.triggers) {
            this.triggers[eventType] = {
                count: this.triggers[eventType].count ? this.triggers[eventType].count + 1 : 1
            }

            this.stateUpdated.dispatchListeners()
        }
    }

    /**
     * Reset trigger notification
     * @param {string} triggerType trigger type
     */
    resetNotification(triggerType) {
        /**
         * Pseudo Code
         *  If trigger type count exist
         *      delete count
         *      dispatch state updated listeners
         */
        if (this.triggers[triggerType]?.count) {
            delete this.triggers[triggerType].count

            this.stateUpdated.dispatchListeners()
        }
    }

    /**
     * Initialize socket triggers
     */
    initSocketState() {
        this.triggers = {}
    }
}

export default SocketState