/**
 * Class for handling live ratings REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file ratingsLive.js
 * @author Matt Schreider, Paul Scala, Kyle Watkins
 */

class RatingsLive {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * @description Get System Messages
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.startDate beginning date 
     * @param {number} searchFilters.endDate end date
     * @param {string[]} searchFilters.type rating type
     * @param {number} searchFilters.priorityLimit priority limit value
     * @returns {ResponseObject} Api response object 
     */
    async getComfortRating(siteId, {startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime(), endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1) - 1, type = ['Comfort'], priorityLimit = null} = {}) {
        let searchFilters = {
            startDate,
            endDate,
            type,
        }

        let query = this.session.getQuery({siteId, searchFilters})

        let config = {
            method: 'get',
            url: `/api/ratingslive${"?Method=Range&" + (priorityLimit !== null ? `priorityLimit=${priorityLimit}&` : '') + query}`,
        }

        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get air quality rating for site
     * @param {string} siteId 
     * @param {object} searchFilters
     * @param {number} searchFilters.startDate beginning date 
     * @param {number} searchFilters.endDate end date
     * @param {string[]} searchfilters.type rating type
     * @param {number} searchFilters.priorityLimit priority limit value
     * @returns {ResponseObject} Api response object 
     */
    async getAirQualityRating(siteId, {startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime(), endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1) - 1, type = ['AirQuality'], priorityLimit = null} = {}) {
        // TODO Add Severity to search 
        let searchFilters = {
            startDate,
            endDate,
            type,
        }

        let query = this.session.getQuery({siteId, searchFilters})

        let config = {
            method: 'get',
            url: `/api/ratingslive${"?Method=Range&" + (priorityLimit !== null ? `priorityLimit=${priorityLimit}&` : '') + query}`,
        }

        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get ratings for site
     * @param {string} siteId Site id to get ratings for
     * @param {object} searchFilters
     * @param {string} searchFilters.type Rating type to get
     * @param {number} searchFilters.startDate beginning date 
     * @param {number} searchFilters.endDate end date
     * @param {string[]} searchfilters.populate populate issues, defaults to 'none'
     * @param {number} searchfilters.priorityLimit priority limit value
     * @returns {ResponseObject} Api response object 
     */
    async getRating(siteId, {type = [], startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime(), endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1) - 1, priorityLimit = null, populate = 'none'} = {}) {
        // TODO Add Severity to search 
        let searchFilters = {
            startDate,
            endDate,
            type,
        }

        let query = this.session.getQuery({page: null, siteId, searchFilters})

        let config = {
            method: 'get',
            url: `/api/ratingslive${"?Method=Range&" + `populate=${populate}&` + (priorityLimit !== null ? `priorityLimit=${priorityLimit}&` : '') + query}`,
        }

        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
}

export default RatingsLive