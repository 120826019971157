/**
 * Base Component
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file base.js
 * @author Kyle Watkins, Matt Schreider, Paul Scala
 */

import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import PageTitle from "./stateless/PageTitle"

/**
 * @description Base routing
 * @param {Object} props Properties of Loading
 * @param {object[]} props.pages Base pages to build out
 * @returns Login routing
 */
function Base(props) {
    let links = props.pages
    const alt = []
    
    let pages = links.map((link) => {
        let path = link.route
        let alternativeRoutes = link?.alternativeRoutes ?? []

        alternativeRoutes.map(altRoute => {
            alt.push(
                <Route key={altRoute} path={altRoute}>
                    <Redirect to={link.route} />
                </Route>
            )
        })

        return (
            <Route key={path} exact path={path}> 
                <PageTitle title={path}>
                    <link.component name={link.name} route={path} icon={link.icon} faIcon={link.faIcon} />
                </PageTitle>
            </Route>
        )
    })

    return (
        <div className="imageHolder">
            <PageTitle title='' />
            <Switch>
                {
                    pages
                }
                {
                    alt
                }
            </Switch>
        </div>
    )
}

export default Base