/**
 * Class for routing REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file router.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/

import Auth from "./auth"
import Alerts from "./alerts"
import Sites from "./sites"
import Users from "./users"
import Subscriptions from "./subscriptions"
import Devices from "./devices"
import AlertAnalytics from "./alertAnalytics"
import SensorAnalytics from "./sensorAnalytics"
import Subsites from "./subsites"
import Groups from "./groups"
import Lists from "./lists"
import SystemMessages from "./systemmessages"
import Ratings from "./ratings"
import UserRoles from "./userroles"
import Weather from "./weather"
import RatingsLive from "./ratingsLive"
import EmailLog from "./emailLog"
import SmsLog from "./smsLog"
import Blacklists from "./blacklists"
import Models from "./models"
import RatingsTypes from './ratingsTypes'
import AlertTypes from "./alertTypes"
import RatingsLiveAnalytics from './ratingsLiveAnalytics'
import About from "./about"
import WebhookRequests from "./webhookRequests"
import WebhookEvents from "./webhookEvents"
import WebhookVariables from "./webhookVariables"
import WebhookLog from "./webhookLog"
import Forecast from "./forecast"

/**
 * Class for routing REST request
 */
class Router {
    /**
     * Initialize request router 
     * @param {object} axios Axios instance
     * @param {class} session Handle session values, such as permissions and site ids
     */
    constructor(axios, session) {
        this.auth = new Auth(axios, session)
        this.alerts = new Alerts(axios, session)
        this.sites = new Sites(axios, session)
        this.users = new Users(axios, session)
        this.subscriptions = new Subscriptions(axios, session)
        this.devices = new Devices(axios, session)
        this.groups = new Groups(axios, session)
        this.lists = new Lists(axios, session)
        this.subsites = new Subsites(axios, session)
        this.systemmessages = new SystemMessages(axios, session)
        this.ratings = new Ratings(axios, session)
        this.userroles = new UserRoles(axios, session)
        this.weather = new Weather(axios, session)
        this.alertanalytics = new AlertAnalytics(axios, session)
        this.sensoranalytics = new SensorAnalytics(axios, session)
        this.ratingsLive = new RatingsLive(axios, session)
        this.emailLog = new EmailLog(axios, session)
        this.smsLog = new SmsLog(axios, session)
        this.blacklists = new Blacklists(axios, session)
        this.models = new Models(axios, session)
        this.ratingsTypes = new RatingsTypes(axios, session)
        this.alertTypes = new AlertTypes(axios, session)
        this.ratingsLiveAnalytics = new RatingsLiveAnalytics(axios, session)
        this.about = new About(axios, session)
        this.webhookConfigs = new WebhookRequests(axios, session)
        this.webhookEvents = new WebhookEvents(axios, session)
        this.webhookVariables = new WebhookVariables(axios, session)
        this.webhookLog = new WebhookLog(axios, session)
        this.forecast = new Forecast(axios, session)
    }
}

export default Router