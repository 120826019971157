import React from "react"

/**
 * Component for Glass Box header
 * @param {object} props Component props
 * @param {string} props.title Title of header
 */
function GlassHeader({title}) {
    return (
        <>
            <div className="rootHeader" style={{padding: '4px 0px'}}>
                <span className="rootTitle" style={{justifyContent: 'center'}}>
                    {title}
                </span>
            </div>
            <div className="horizontalDivider" />
        </>
    )
}

export default GlassHeader