/**
 * Web Socket Manager Class
 * @copyright 2021-2023 Soter Technologies, LLC. All rights reserved.
 * @file WebSocketManager.js
 * @author Matt Schreider, Paul Scala, Kyle Watkins
 */

import SocketState from "./webSockets/helpers/socketState"

/**
 * Singleton class for managing Web Sockets
 */
class WebSocketManager {
    constructor(websocket, session) {
        this.rooms = []

        this.websocket = websocket
        this.session = session

        this.isConnected = false
        this.hasFirstConnect = false

        this.websocket.onConnectListener.addListener(this.onConnect)
        this.websocket.onDisconnectListener.addListener(this.onDisconnect)
        this.websocket.onJoinRoomListener.addListener(this.onJoinRoom)
        this.websocket.onLeaveRoomListener.addListener(this.onLeaveRoom)

        this.session.addSiteIdListener(this.siteIdChanged)

        this.socketState = new SocketState(this.websocket, this.rooms)
    }

    /**
     * initialize websocket service
     */
    initialize() {
        this.websocket.initialize(this.session)
    }

    /**
     * terminate websocket service
     */
    terminate() {
        // leave all rooms
        this.leaveAllRooms()
        
        // terminate socket
        this.websocket.terminate(this.session)
    }

    /**
     * Event handler for websocket connected
     */
    onConnect = () => {
        // update connection status
        this.isConnected = true

        // Check first connect
        if (this.hasFirstConnect === false) {
            // Set first connect to true
            this.hasFirstConnect = true

            // Listen to incoming events
            this.websocket.alerts.listen()
        }

        // join alerts room for current site
        this.websocket.alerts.joinRoom({siteId: this.session.getCurrentSiteId()})
    }

    /**
     * Event handler for websocket disconnect
     */
    onDisconnect = () => {
        this.isConnected = false
    }

    /**
     * Event handler for websocket join room
     * @param {object} msg join message
     */
    onJoinRoom = (msg) => {
        // add type to triggers
        this.rooms.push({
            type: msg.EventType
        })

        this.socketState.addTrigger({action: msg.Action, eventType: msg.EventType})
    }

    /**
     * Event handler for websocket leave room
     * @param {object} msg leave message
     */
    onLeaveRoom = (msg) => {
        // remove room from rooms array
        this.rooms = this.rooms.filter((room) => room.type !== msg.EventType)

        // remove trigger from socket state
        this.socketState.removeTrigger(msg.EventType)
    }

    /**
     * Get websocket connection status
     * @returns {boolean} connection status
     */
    getIsConnected = () => {
        return this.isConnected
    }

    /**
     * Event handler for when site id changes
     * @param {string} prevSiteId 
     * @param {string} currentSiteId 
     */
    siteIdChanged = (prevSiteId, currentSiteId) => {
        // leave all rooms and join new rooms
        for (const room of this.rooms) {
            if (room.type === 'alerts') {
                // leave current room
                this.websocket.alerts.leaveRoom({siteId: prevSiteId})

                // join new room
                this.websocket.alerts.joinRoom({siteId: currentSiteId})                
            }
        }
    }

    /**
     * leave all rooms currently in
     */
    leaveAllRooms() {
        // leave all rooms
        this.rooms.forEach((room) => {
            if (room.type === 'alerts') {
                this.websocket.alerts.leaveRoom({siteId: this.session.getCurrentSiteId()})
            }
        })
    }

    /**
     * Adds session to be used inside of web socket manager
     * @param {object} webSocket Add session to web socket manager after construction of both classes
     */
    addSession(session) {
        this.session = session
    }
}

export default WebSocketManager