/**
 * Authorized Error Component
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file alertAnalytics.js
 * @author Kyle Watkins, Paul Scala, Matt Schreider
 */

import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

/**
 * @description Home Page
 * @param {Objects} props 
 * @param {string} props.name Page name
 * @param {string} props.route Page route
 * @param {string} props.icon Icon name
 */
class authError extends React.Component {
    constructor(props) {
        /**
         * Psuedo Code
         *  If name is sitenotfound
         *      Set message and action
         *  If name is timeout
         *      Set message and action
         *  If name is pagenotfound
         *      Set message and action
         *  If name is error
         *      Set message and action
         */
        
        super(props)
        this.style = {height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", color: "grey", fontWeight: "bolder"}
        if (props.name === 'sitenotfound') {
            this.message = 'Site Not Found' 
            this.action = {text: 'Go To Dashboard', function: () => {
                this.props.history.push('/dashboard?siteId=')
            }}
        }
        else if (props.name === 'timeout') {
            this.message = 'Server Timed Out' 
            this.action = {text: 'Try Again', function: () => {
                this.props.history.push(this.props.history.location.state ?? '/dashboard')
            }}
        }
        else if (props.name === 'pagenotfound') {
            this.message = 'Page Not Found'
            this.action = {
                text: 'Go To Dashboard', function: () => {
                    this.props.history.push("/dashboard")
                }
            } 
        }
        else if (props.name === 'error') {
            this.message = 'Unexpected Issue Please Refresh'
            this.action = {
                text: 'Refresh', function: () => {
                    window.location.reload()
                }
            } 
        }
    }

    render() {
        return (
            <div className='page'>
                <div className="row middle">
                    <div className="notfound transparent" style={this.style}>
                        <div style={{height: "48px"}}>
                            {this.message}
                        </div>
                        {
                            this.action &&
                                <Button onClick={this.action?.function}>
                                    {this.action?.text}
                                </Button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(authError)