/**
 * Eot Live Text Logo
 * @copyright 2021-2023 Soter Technologies, LLC. All rights reserved.
 * @file EotLive.js
 * @author Kyle Watkins, Matt Schreider, Paul Scala
 */

import React from 'react'
import '../resources/less/logo.less'

/**
 * Eot live logo component
 * @param {object} props Component props
 * @param {'small'|'medium'} props.size
 */
function EotLive({size = 'small'}) {
    return (
        <div className={`logoOuter ${size}`}>
            <div className='logoBox eot'>
                <h2 className='eotText'>
                    EoT
                </h2>
            </div>
            <div className='logoBox live'>
                <div className='liveText'>
                    LIVE
                </div>
            </div>
        </div>
    )
}

export default EotLive