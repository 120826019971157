/**
 * Class for handling site REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file subscriptions.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/

class Subscriptions {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }
    
    /**
     * Get subscriptions by siteId and filters
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {boolean} searchFilters.isActive Filter by isActive
     * @param {string[]} searchFilters.deviceIds Device ids to filter by
     * @param {string[]} searchFilters.userIds User ids to filter by
     * @param {string[]} searchFilters.alertType Alert types to filter by, defaults to ['All']
     * @param {string[]} searchFilters.groupIds Group Ids to filter by
     * @param {string[]} searchFilters.listIds List Ids to filter by
     * @returns {ResponseObject} Api response object
     */
    async getSubscriptions(siteId = null, {page = 0, order = "ASC", search = "", sortBy = "", isActive = null, deviceIds = [], userIds = [], alertType = ['All'], groupIds = [], listIds = []} = {}) {
        
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            ...(isActive !== null && {isActive: isActive}),
            userId: userIds,
            deviceId: deviceIds,
            alertType: alertType,
            groupId: groupIds,
            listId: listIds
        }
        
        let query = this.session.getQuery({page, siteId, searchFilters})
        
        let config = {
            method: 'get',
            url: `/api/subscriptions${"?" + query}`,
        }
        
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })

    }

    /**
     * Get subscriptions by day
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {boolean} searchFilters.isActive Filter by isActive
     * @param {string[]} searchFilters.dayOfWeek Filter by day of week, defaults to all
     * @param {string[]} searchFilters.deviceIds Device ids to filter by
     * @param {string[]} searchFilters.userIds User ids to filter by
     * @param {string[]} searchFilters.alertType Alert types to filter by, defaults to ['All']
     * @param {string[]} searchFilters.groupIds Group Ids to filter by
     * @param {string[]} searchFilters.listIds List Ids to filter by
     * @returns {ResponseObject} Api response object
     */
    async getSubscriptionsByDay(siteId = null, {page = 0, order = "ASC", search = "", sortBy = "", isActive = null, daysOfWeek = ['All'], deviceIds = [], userIds = [], alertType = ['All'], groupIds = [], listIds = []} = {}) {
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            ...(isActive !== null && {isActive: isActive}),
            daysOfWeek: daysOfWeek,
            userId: userIds,
            deviceId: deviceIds,
            alertType: alertType,
            groupId: groupIds,
            listId: listIds,
        }
        
        let query = this.session.getQuery({page, siteId, searchFilters})
        
        let config = {
            method: 'get',
            url: `/api/subscriptions/by/day${"?" + query}`,
        }
        
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Get a single subscription information by id
     * @param {string} id Subscription uuid  
     * @returns {ResponseObject} Api response object
     */
    async getSubscription({id}) {
        let config = {
            method: 'get',
            url: `/api/subscriptions/${id}?populate=simple`
        }

        let res = await this.axios(config)
        
        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        }) 
    }

    /**
     * @description Delete subscription by id
     * @param {string} id Subscription uuid     
     * @returns {ResponseObject} Api response object
     */
    async deleteSubscription({id}) {
        let config = {
            method: 'delete',
            url: `/api/subscriptions/${id}`
        }

        let res = await this.axios(config)

        return({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        }) 
    }

    /**
     * Save subscriptions configuration
     * @param {object} params Subscription parameters
     * @param {string} params.siteId Site id of site
     * @param {string} params.isActive Subscription is active, default true
     * @param {string} params.type Subscription type
     * @param {string[]} params.sites Array of sites uuids in subscription
     * @param {string[]} params.devices Array of device uuids in subscription
     * @param {string[]} params.lists Array of lists uuids in subscription
     * @param {string[]} params.users Array of users uuids in subscription
     * @param {string[]} params.groups Array of groups uuids in subscription
     * @param {string[]} params.events Array of events uuids in subscription
     * @param {number} params.startTime Start time by hour
     * @param {number} params.endTime End time by hour
     * @param {boolean} params.sunday If true alerts will be sent on sunday
     * @param {boolean} params.monday If true alerts will be sent on monday
     * @param {boolean} params.tuesday If true alerts will be sent on tuesday
     * @param {boolean} params.wednesday If true alerts will be sent on wednesday
     * @param {boolean} params.thursday If true alerts will be sent on thursday
     * @param {boolean} params.friday If true alerts will be sent on friday
     * @param {boolean} params.saturday If true alerts will be sent on saturday
     * @param {string[]} params.alertTypes Alert types for subscription to receive
     * @returns {ResponseObject} Api response object
     */
    async createSubscription({siteId = null, name = "", isActive = true, type = 'Devices', sites = [], devices = [], lists = [], users = [], groups = [], events = [], startTime = 7, endTime = 19, sunday = false, monday = false, tuesday = false, wednesday = false, thursday = false, friday = false, saturday = false, alertTypes = []}) {
        if (siteId === null) {
            siteId = this.session.getSiteId()
        }

        let data = {
            IsActive: isActive,
            Name: name,
            Site: siteId,
            Type: type,
            Devices: devices.map(d => d.guid),
            Lists: lists.map(d => d.guid),
            Sites: sites.map(d => d.guid),
            Users: users.map(d => d.guid),
            Groups: groups.map(d => d.guid),
            Events: events,
            StartTime: parseFloat(startTime),
            EndTime: parseFloat(endTime),
            Sunday: sunday,
            Monday: monday,
            Tuesday: tuesday,
            Wednesday: wednesday,
            Thursday: thursday,
            Friday: friday,
            Saturday: saturday,
            AlertTypes: (Array.isArray(alertTypes) && alertTypes.includes('All')) ? ['All'] : alertTypes.map(d => d.guid),
        } 
        
        let config = {
            method: 'post',
            url: '/api/subscriptions',
            data: data
        }

        let res = await this.axios(config)

        return({
            status: res.status,
            statusText: res.statusText, 
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        }) 
    }

    /**
     * Save subscriptions configuration
     * @param {object} params Subscription parameters
     * @param {number} params.id Subscription id
     * @param {string} params.isActive Subscription is active
     * @param {string} params.type Subscription type
     * @param {string[]} params.sites Array of sites uuids in subscription
     * @param {string[]} params.devices Array of device uuids in subscription
     * @param {string[]} params.lists Array of lists uuids in subscription
     * @param {string[]} params.users Array of users uuids in subscription
     * @param {string[]} params.groups Array of groups uuids in subscription
     * @param {string[]} params.events Array of events uuids in subscription
     * @param {number} params.startTime Start time by hour
     * @param {number} params.endTime End time by hour
     * @param {boolean} params.sunday If true alerts will be sent on sunday
     * @param {boolean} params.monday If true alerts will be sent on monday
     * @param {boolean} params.tuesday If true alerts will be sent on tuesday
     * @param {boolean} params.wednesday If true alerts will be sent on wednesday
     * @param {boolean} params.thursday If true alerts will be sent on thursday
     * @param {boolean} params.friday If true alerts will be sent on friday
     * @param {boolean} params.saturday If true alerts will be sent on saturday
     * @param {string[]} params.alertTypes Alert types for subscription to receive
     * @returns {ResponseObject} Api response object
     */
    async saveSubscription({id = null, name = "", isActive = true, type = 'Devices', sites = [], devices = [], lists = [], users = [], groups = [], events = [], startTime = 7, endTime = 19, sunday = false, monday = false, tuesday = false, wednesday = false, thursday = false, friday = false, saturday = false, alertTypes = []}) {
        let data = {
            IsActive: isActive,
            Name: name,
            Type: type,
            Devices: devices.map(d => d.guid),
            Lists: lists.map(d => d.guid),
            Sites: sites.map(d => d.guid),
            Users: users.map(d => d.guid),
            Groups: groups.map(d => d.guid),
            Events: events,
            StartTime: parseFloat(startTime),
            EndTime: parseFloat(endTime),
            Sunday: sunday,
            Monday: monday,
            Tuesday: tuesday,
            Wednesday: wednesday,
            Thursday: thursday,
            Friday: friday,
            Saturday: saturday,
            AlertTypes: (Array.isArray(alertTypes) && alertTypes.includes('All')) ? ['All'] : alertTypes.map(d => d.guid),
        } 
        
        let config = {
            method: 'put',
            url: `/api/subscriptions/${id}`,
            data: data
        }

        let res = await this.axios(config)

        return({
            status: res.status,
            statusText: res.statusText, 
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        }) 
    }
}

export default Subscriptions