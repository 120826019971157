import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import ApiManager from '../api/ApiManager'
import { decodeSearchParams } from '../utilities/utilityFunctions'
import Notification from '../stateless/Notification'

/**
 * @description Takes in page name and icon and returns a Navigation Item
 * @param {Object} props Component Properties
 * @param {string} props.name Page name
 * @param {string} props.icon icon name
 * @param {string} props.faIcon Icon name
 * @param {boolean} props.active For handling active flag and highlighting the nav item when on a subpage
 * @returns NavItem
**/
function NavItem (props) {
    // Get page name
    let route = (props.name).toLowerCase()
    let icon = (<i name={props.icon} className={props.icon} />)
    
    // Get current and default site id 
    let { siteId } = decodeSearchParams()

    if (siteId === undefined) {
        siteId = ApiManager.session.getSiteId()
    }
    
    let defaultSiteId = ApiManager.session.getSiteId("default")

    let search = ""

    // If site Id exist, and is not equal to broken or default site id then set the search for a nav item
    if (siteId && siteId !== "broken" && siteId !== defaultSiteId) {
        search = "siteId=" + siteId
    }

    // Handle sub url to show nav item as active if the current page is a sub url
    let active
    let path = props.location.pathname.substring(1).split("/")
    
    // Remove last element of array before check
    path.pop()

    if (path.includes(route)) {
        active = true
    }
    
    return (
        <NavLink exact to={`/${route}?${search}`} className={"navMenu-item" + ((active === true) ? " active" : "")} activeClassName="active" onClick={props.onClick}>
            {icon}
            {" "}
            <span style={{position: 'relative'}}>
                {props.name}
                {
                    props.notifications?.count > 0 && <Notification count={props.notifications.count} />
                }
            </span>
                
            
        </NavLink> 
    )
}

export default withRouter(NavItem)