/**
 * Class for handling lists REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file lists.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/

class Lists {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * @description Gets lists
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {boolean} searchFilters.isActive Filter by isActive
     * @returns {ResponseObject} Api response object
     */
    async getLists(siteId = null, {page = 0, order = "ASC", search = "", sortBy = "", isActive = null } = {}) {
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            ...(isActive !== null && {isActive: isActive}),
        }

        let query = this.session.getQuery({page, siteId, searchFilters})

        let config = {
            method: 'get',
            url: `/api/lists${"?" + query}`,
        }

        let res = await this.axios(config) 
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get filtered list names
     * @param {string} siteId Site id to get lists for 
     * @param {object} query Query to filter
     * @param {number} query.page Page number of data
     * @param {string} query.order Order of data
     * @param {string} query.search String to search 
     * @param {string} query.sortBy Field to sort by
     * @param {boolean} query.countOnly Only get the count of lists back
     * @returns {ResponseObject} Api response object
     */
    async lookupLists(siteId, {page = 0, order = 'ASC', search = "", sortBy = "", countOnly = false} = {}) {
        /**
         * Pseudo Code
         *  Initialize search filters
         *  Get query to filter by
         *  Set request config
         *  Get lists
         */

        // Initialize search filters
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
        }

        // Get query to filter by
        let query = this.session.getQuery({page, siteId, searchFilters, countOnly})

        // Set request config
        let config = {
            method: 'get',
            url: `/api/lists/lookup${"?" + query}`,
        }

        // Get lists
        let res = await this.axios(config)
    
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get a list's name by id
     * @param {string} id Id of list to get name for
     * @returns {ResponseObject} Api response object
     */
    async getListName(id) {
        // Set request config
        let config = {
            method: 'get',
            url: `/api/lists/${id}/name`,
        }
        
        // Get list
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    } 

    /**
     * @description Get list count
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.search Search by value
     * @returns {ResponseObject} Api response object
     */
    async getCount(siteId = null, {page = 0, search = ""} = {}) {
        let searchFilters = {
            ...(search === "") ? {} : {search},
            countOnly: true
        }

        let query = this.session.getQuery({page, siteId, searchFilters})
        let config = {
            method: 'get',
            url: `/api/lists${"?" + query}`,
        }

        let res = await this.axios(config) 
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Get a single list's information by id
     * @param {string} id List uuid  
     * @returns {ResponseObject} Api response object
     */
    async getList({id}) {
        let config = {
            method: 'get',
            url: `/api/lists/${id}?populate=simple`,
        }

        let res = await this.axios(config) 
        
        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Deletes a specified list by id
     * @param {Object} params Function parameters
     * @param {string} params.id list id
     * @returns {ResponseObject} Api response object
     */
    async deleteList({id}) {
        let config = {
            method: 'delete',
            url: `/api/lists/${id}`,
        }
         
        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Creates a specified list
     * @param {Object} params Function parameters
     * @param {string} params.siteId Site id of list
     * @param {string} params.name List name
     * @param {string[]} params.items List of users
     * @returns {ResponseObject} Api response object
     */
    async createList({siteId = null, name, items}) {
        if (siteId === null) {
            siteId = this.session.getSiteId()
        }

        let data = {
            Name: name,
            Site: siteId,
            Users: items
        }
        
        let config = {
            method: 'post',
            url: '/api/lists',
            data: data
        }
         
        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Saves a specified list by id
     * @param {Object} params Function parameters
     * @param {string} params.name List name
     * @param {boolean} params.isActive List isActive
     * @param {string[]} params.items List of users
     * @returns List saved status
     */
    async saveList({id, name, isActive, items}) {
        let data = {
            Name: name,
            Users: items,
            IsActive: isActive
        }
        
        let config = {
            method: 'put',
            url: `/api/lists/${id}`,
            data: data
        }
         
        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
}

export default Lists