/**
 * Error Catcher Component
 * @copyright 2021-2023 Soter Technologies, LLC. All rights reserved.
 * @file ErrorCatcher.js
 * @author Kyle Watkins, Matt Schreider, Paul Scala
 */

import React from "react"
import { devLog } from "../utilities/loggingUtility"
import AuthError from "../pages/authError"

/**
 * Error Catcher Component
 * @description Displaces child, on error displays refresh 
 * @param {Object} props Properties
 * @param {object[]} props.children Child component to display
 * @usage React error boundary
 */
class ErrorCatcher extends React.Component {
    constructor(props) {
        super(props)
        
        // Set State
        this.state = {
            hasError: false
        }
    }

    componentDidCatch = (error, errorInfo) => {
        /**
         * Pseudo Code
         *  Log to dev log
         */
        devLog(error, errorInfo)
    }

    static getDerivedStateFromError = () => {
        /**
         * Pseudo Code
         *  Set had error to true
         */
        return { hasError: true }
    }

    render () {
        /**
         * Pseudo Code
         *  If has error is false display children
         *  Else display refresh
         */

        if (this.state.hasError === false) {
            return (this.props.children)
        }
        else {
            return (
                <AuthError name="error"></AuthError>
            )
        }
    }
}

export default ErrorCatcher